// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '75%',
        },
        margin: 'auto',
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    descTitleContainer: {
        marginBottom: '30px',
        marginTop: '30px',
    },

    descTitle: {
        fontFamily: 'bold',
        textTransform: 'uppercase',
        fontSize: '30px',
        textAlign: 'center',
    },

    devDescriptionText: {
        fontFamily: 'poppins',
        color: '#616161',
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '27px',
    },

    devDescriptionTextContainer: {
        marginBottom: '50px',
        width: '80%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    devMainContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    imgContainer: {
        width: '180px',
        transition: 'all ease 0.5s',
    },

    nameContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    devName: {
        fontFamily: 'semibold',
    },

    positionContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
    },

    positionText: {
        fontFamily: 'poppins',
        color: '#212121',
        fontSize: '14px',
    },

    socmedMainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
    },

    socmedContainer: {
        border: '1px solid #6e6e6e',
        width: '27px',
        height: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '1px',
        color: '#6e6e6e',

        '&:hover': {
            border: '1px solid #ad230e',
            backgroundColor: '#ad230e',
            transition: 'all ease .5s',
            color: '#fff',
        },
    },

    socmedIcon: {
        fontSize: '18px',
    },
}
export default style