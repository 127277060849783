import React from 'react'
import {Helmet} from "react-helmet";
import style from '../styles/Profile'

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

// Import Icon
import SettingsIcon from '@mui/icons-material/Settings';

// Import Icon
// import product from '../image/Product/Sample.jpg'

// Import Material UIs
import {
    Box,
    Typography,
    Grid,
    Button,
    Modal,
    TextField,
    Link,
    Tooltip,
} from '@mui/material';

//firebase 
import {signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
//redux
import {useSelector} from 'react-redux'

function Profile({ isAuth }) {
    const user = useSelector(state => state.user)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [state] = React.useState({
        name: user.currentUserData[0].UserName,
        email: user.currentUserData[0].UserEmail,
        phone: user.currentUserData[0].ContactNumber,
        address: user.currentUserData[0].UserAddress,
    });
const buttonLogout = () => {
    signOut(auth).then(() => {});
}
    return (
        <Box sx={{ backgroundColor: '#f2f2f2', }}>
             <Helmet>
             <title>DC Monorack - Profile </title>
            <meta name="DC Monorack Profile " 
            content="Questions and Inquiries? You may directly contact us" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

            <Navbar isAuth={isAuth}/>

            {/*BreadCrumbs*/}
            <Box sx={style.breadcrumbsContainer}>
                <Typography sx={style.breadcrumbsTitle}>Profile</Typography>
                <Typography sx={style.breadcrumbsSubtitle}>Questions and Inquiries? You may directly contact us</Typography>
            </Box>

            <Box sx={style.mainContainer}>

                {/* Info Container */}
                <Grid container sx={style.userContainer}>
                    <Grid item xs={12} sm={12} md={11} lg={11}>
                        <Box sx={style.userInfoContainer}>
                            <Typography sx={style.infoName}>{user.currentUserData[0].UserName}</Typography>
                            <Typography sx={style.infoAddress}>{user.currentUserData[0].UserAddress}</Typography>
                            <Typography sx={style.infoEmail}>{user.currentUserData[0].UserEmail}</Typography>
                            <Typography sx={style.infoContact}>0{user.currentUserData[0].ContactNumber}</Typography>
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={1} lg={1}>
                        <Box sx={style.buttonContainer}>
                            <Button variant="contained" sx={style.editButton} onClick={handleOpen}>
                                <SettingsIcon sx={style.icon} />
                            </Button>
                        </Box>
                    </Grid>
                </Grid>

                {/* Order Status */}
                <Box sx={style.orderContainer}>
                    <Typography sx={style.titleOrder}>Your Orders</Typography>

                    {/* Per Order */}
                    { user.orders.map((order) => (
                    <Box sx={style.orderBox}>
                        <Grid container>

                            {/* Product Information */}
                            <Grid item xs={12} sm={12} md={11} lg={11}>
                                <Grid container sx={style.productImageGrid}>

                                    {/* Product Image */}
                                   
                                    <Grid item>
                                        <Box>
                                            <img
                                                alt="Order_Image"
                                                src={order.ProductImage}
                                                style={{
                                                    width: '100px',
                                                    height: '100px',
                                                }}
                                            />
                                        </Box>
                                    </Grid>

                                    {/* Product Details */}
                                    <Grid item xs={12} sm={12} md={10} lg={10}>
                                        <Box sx={style.productDetails}>
                                            {order.JntTracking && 
                                            <Box sx={{display:"flex"}}>
                                            <Tooltip title="Click to Copy">
                                            <Typography onClick={()=> navigator.clipboard.writeText(order.JntTracking) } sx={style.productId}>{order.JntTracking}</Typography></Tooltip>
                                             <Link href="https://www.jtexpress.ph/index/query/gzquery.html" sx={{ml:2}}>Track Now</Link>
                                                 </Box> }
                                            
                                       
                                            <Typography sx={style.productTitle}>{order.ProductName}</Typography>
                                            <Typography sx={style.productDescription}>{order.ProductDesc}</Typography>
                                            <Box sx={style.priceBox}>
                                                <Typography sx={style.priceLabel}>Total Price:</Typography>
                                                <Typography sx={style.priceText}>{order.Total}.00</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                                   

                            {/* Product Status */}
                            <Grid item xs={12} sm={12} md={1} lg={1}>
                                <Box sx={style.statusContainer}>
                                    <Typography sx={style.statusPending}>{order.OrderStatus}</Typography>
                                </Box>
                            </Grid>
 
                        </Grid>
                    </Box> ))}

                   

                    

                </Box>

            </Box>

            {/* Modal Start*/}

            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style.boxModal}>
                    <Box sx={style.modalContainer}>

                        <Box sx={style.headerModal}>
                            <SettingsIcon sx={style.modalIcon}/>
                            <Typography sx={style.modalHeadText}>Account Settings</Typography>
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Typography sx={style.modalLabel}>Full Name</Typography>
                            <TextField sx={style.textBoxModal} 
                            value={state.name}
                            />
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Typography sx={style.modalLabel}>Address</Typography>
                            <TextField sx={style.textBoxModal}
                            value={state.address}
                            />
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Typography sx={style.modalLabel}>Contact No.</Typography>
                            <TextField sx={style.textBoxModal} 
                            value={state.phone}
                            />
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Typography sx={style.modalLabel}>Email</Typography>
                            <TextField sx={style.textBoxModal} 
                            disabled
                            value={state.email}
                            />
                        </Box>


                        <Box sx={style.perItemModal}>
                            <Button sx={style.uploadButton}>
                                Upload Picture
                            </Button>
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Button sx={style.saveButton}>
                                Save
                            </Button>
                        </Box>

                        <Box sx={style.perItemModal}>
                            <Button sx={style.logoutButton} onClick={buttonLogout}>
                                Logout
                            </Button>
                        </Box>

                    </Box>
                </Box>
            </Modal>

            {/* Modal End */}


            <Footer />
        </Box>
    )
}

export default Profile