import React, { useState } from "react";
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// Import Styling
import style from "../styles/Login";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";

// Import Material UI
import { Box, Typography, Button, TextField, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
//backend
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../utils/firebase";
import { where, collection, query, onSnapshot } from "firebase/firestore";


function Login({isAuth}) {
  const history = useHistory();
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [state, setstate] = useState({
    email: "",
    password: "",
  });

  const handleChange = (prop) => (e) => {
    setstate({ ...state, [prop]: e.target.value });
  };

 const handleSubmit =  () =>  {
    
    setIsSubmiting(true);
    console.log(state.email,state.password);
    signInWithEmailAndPassword(
        auth,
        state.email,
        state.password
      )
        .then((userCredential) => {
          const user = userCredential.user;
         

          if ( user.emailVerified === true) {
            const collectionRefMyData = collection(db, "Users");
            const MyData = query(
              collectionRefMyData,
              where("UserUid", "==", user.uid)
            );
            onSnapshot(MyData, (snapshot) => {
              if (
                snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0]
                  .UserType === "Buyer"
              ) {
                history.push("/");
              } else {
                auth.signOut();
                alert("Your Account is not a Buyer");
              }
            });
          } else {
           
            // alert("Your Email is not verified");
            auth.signOut();
               alert("Your Email is not verified");
            setIsSubmiting(false);
            setIsError(false);
             setIsErrorEmail(true);
          }

        })
        .catch((error) => {
          // error code
          // var errorCode = error.code;
          // var errorMessage = error.message;
          setIsErrorEmail(false);
          setIsError(true);
        //alert(errorCode, errorMessage);
          setIsSubmiting(false);

        });
    console.log(isErrorEmail);
}

const errorMessage = (
    <Box sx={style.warningFailedBox}>
    <Typography sx={style.warningText}>
      Log in FAILED! Please check your credentials.
    </Typography>
  </Box>
)

const errorMessageEmail = (
    <Box sx={style.warningFailedBox}>
    <Typography sx={style.warningText}>
      Log in FAILED! Your Email is not verified.
    </Typography>
  </Box>
)

  return (
    <Box>

       <Helmet>
             <title>DC Monorack - Login </title>
            <meta name="DC Monorack Login " 
            content="Kindly login your credentials to continue transactions. Thank you!" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

      <Navbar isAuth={isAuth}/>

      {/*BreadCrumbs*/}
      <Box sx={style.breadcrumbsContainer}>
        <Typography sx={style.breadcrumbsTitle}>Login Account</Typography>
        <Typography sx={style.breadcrumbsSubtitle}>Home / Login</Typography>
      </Box>

      {/*Login Form*/}

          <Box sx={style.mainLoginContainer}>
            <Box sx={style.loginFormBox}>
              {/*Warning*/}
              
              {isError === true ? errorMessage : null}
              {isErrorEmail === true ? errorMessageEmail : null}

              <Typography sx={style.loginTitle}>Login</Typography>
              <Typography sx={style.directionText}>
                Kindly login your credentials to continue transactions. Thank
                you!
              </Typography>

              <Typography sx={style.credentialsLabel}>Email</Typography>
              <TextField
                sx={style.inputBox}
                type="email"
                value={state.email}
                onChange={handleChange("email")}
              
              />

              <Typography sx={style.credentialsLabel}>Password</Typography>
              <TextField
                sx={style.inputBox}
                type="password"
                value={state.password}
                onChange={handleChange("password")}
              />

              {/*Login*/}
              <Box sx={style.optionContainer}>
                <Link to="/forgotpassword" style={{ textDecoration: "none" }}>
                  <Typography sx={style.forgotPasswordLabel}>
                    Forgot Password?
                  </Typography>
                </Link>

                <LoadingButton
                  sx={style.loginButton}
                  variant="contained"
                  loading={isSubmiting}
                  onClick={handleSubmit}
                >
                  Login
                </LoadingButton>
              </Box>

              {/*Alternate Logins*/}
              <Box sx={style.alternateLoginContainer}>
                <Box sx={style.registerBoxContainer}>
                  <Link
                    to="/register"
                    style={{ textDecoration: "none", marginRight: "5px" }}
                  >
                    <Typography sx={style.forgotPasswordLabel}>
                      No Account? Register Here{" "}
                    </Typography>
                  </Link>
                  <Typography sx={style.credentialsLabel}>
                    Or Login Using:
                  </Typography>
                </Box>

                <Grid container>
                  {/*Google Button*/}
                  <Grid item sx={style.containerButton}>
                    <Button variant="contained" sx={style.googleButton} disabled>
                      <GoogleIcon sx={style.googleIcon} />
                      Google
                    </Button>
                  </Grid>

                  {/*Facebook Button*/}
                  <Grid item sx={style.containerButton}>
                    <Button variant="contained" sx={style.facebookButton} disabled>
                      <FacebookIcon sx={style.facebookIcon} />
                      Facebook
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
     
      <Footer />
    </Box>
  );
}

export default Login;
