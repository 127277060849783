import React, { useState } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
// Import Styling
import style from "../styles/Register";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Material UI
import { Box, Typography, Grid, TextField, } from "@mui/material";
import { LoadingButton } from "@mui/lab";

//backend

import { db, auth } from "../utils/firebase";
import { serverTimestamp,setDoc, doc } from "firebase/firestore";

import { createUserWithEmailAndPassword } from "firebase/auth";
import { sendEmailVerification } from "firebase/auth";

function Register({isAuth}) {
  const [isError, setIsError] = useState(false);
  const [isErrorEmail, setIsErrorEmail] = useState(false);
  const [isSuccessEmail, setIsSuccessEmail] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [state, setstate] = useState({
    email: "",
    name: "",
    contact: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (prop) => (e) => {
    setstate({ ...state, [prop]: e.target.value });
  };

  const handleSubmit = () => {
    // setIsSubmiting(true);
  

    if (state.password !== state.confirmPassword) {
      setIsSubmiting(false);
      setIsErrorEmail(false);
      setIsSuccessEmail(false);
      setIsError(true);
    } else if (
      state.name === "" ||
      state.email === "" ||
      state.contact.length <= 10 ||
      state.password === "" ||
      state.confirmPassword === ""
    ) {
    setIsErrorEmail(false);
      setIsSubmiting(false);
      setIsError(true);
    } else {
        
      createUserWithEmailAndPassword(auth, state.email, state.password).then(
        (userCredential) => {
          sendEmailVerification(auth.currentUser).then(async () => {
 
            await setDoc(doc(db, "Users",userCredential.user.uid), {
              UserUid: userCredential.user.uid,
              UserEmail: state.email,
              Image:"",
              UserName: state.name,
              UserType: "Buyer",
              ContactNumber: Number(state.contact),
              Created: serverTimestamp(),
            });
            setIsError(false);
            setIsErrorEmail(false);
            setIsSuccessEmail(true);

            setTimeout(() => {
                auth.signOut();
              }, 4000);
          });
        }
      ).catch((error) => { 
            // error

        // const errorCode = error.code;
        // const errorMessage = error.message;
        setIsErrorEmail(true);
        setIsSuccessEmail(false);
        setIsError(false);
       
      });

     
    }
  };

  const errorMessage = (
    <Box sx={style.warningFailedBox}>
      <Typography sx={style.warningText}>
        Registration Failed! Please check your Information.
      </Typography>
    </Box>
  );
  const errorEmail = (
    <Box sx={style.warningFailedBox}>
      <Typography sx={style.warningText}>
      Registration Failed! Email is already use.
      </Typography>
    </Box>
  );

  const successMessage = (
    <Box sx={style.warningSuccessBox}>
      <Typography sx={style.warningText}>
        Successfully Registered! Please confirm your email first.
      </Typography>
    </Box>
  );

  return (

      <Box>
      <Helmet>
             <title>DC Monorack - Register </title>
            <meta name="DC Monorack Register " 
            content="Please fill up the Registration Form to Continue." />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

        <Navbar isAuth={isAuth} />

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>Register Account</Typography>
          <Typography sx={style.breadcrumbsSubtitle}>
            Home / Register
          </Typography>
        </Box>

        {/*Registration Form*/}
        <Box sx={style.mainRegistrationContainer}>
          <Box sx={style.registrationFormBox}>
            {/*Warning FAILED*/}
            {isError === true ? errorMessage : null}
            {/*Warning SUCCESS*/}
            {isSuccessEmail === true ? successMessage : null}
            {isErrorEmail === true ? errorEmail : null}

            <Typography sx={style.registerTitle}>Create an Account</Typography>
            <Typography sx={style.directionText}>
              Please fill up the Registration Form to Continue.
            </Typography>

            <Typography sx={style.credentialsLabel}>Full Name</Typography>
            <TextField
              sx={style.inputBox}
              value={state.name}
              type="name"
              onChange={handleChange("name")}
            />

            <Typography sx={style.credentialsLabel}>Contact No.</Typography>
            <TextField
              sx={style.inputBox}
              value={state.contact}
              type="number"
              onChange={handleChange("contact")}
            />

            <Typography sx={style.credentialsLabel}>Email Address</Typography>
            <TextField
              sx={style.inputBox}
              value={state.email}
              type="email"
              onChange={handleChange("email")}
            />

            <Typography sx={style.credentialsLabel}>Password</Typography>
            <TextField
              sx={style.inputBox}
              value={state.password}
              type="password"
              onChange={handleChange("password")}
            />

            <Typography sx={style.credentialsLabel}>
              Confirm Password
            </Typography>
            <TextField
              sx={style.inputBox}
              value={state.confirmPassword}
              type="password"
              onChange={handleChange("confirmPassword")}
            />

            {/*Buttons*/}
            <Box sx={style.buttonContainer}>
              <Grid container>
                <Grid item sx={style.containerButton}>
                  <LoadingButton
                    sx={style.createButton}
                    variant="contained"
                    loading={isSubmiting}
                    onClick={handleSubmit}
                  >
                    Create
                  </LoadingButton>
                </Grid>

                <Grid item sx={style.containerLink}>
                  <Link
                    to="/"
                    style={{ textDecoration: "none", marginRight: "5px" }}
                  >
                    <Typography sx={style.returnToShop}>
                      Or Return to Shop
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Footer />
      </Box>
 
  );
}

export default Register;
