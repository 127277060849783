// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '70%',
        },
        margin: 'auto',
        marginTop: '30px',
        marginBottom: '30px',
    },

    contactMainBox: {
        padding: '10px',
    },

    textInstruction: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    boxInfoMain: {
        height: '120px',
        border: '1px solid #e6e6e6',
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        cursor: 'pointer',
    },

    textBoxContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },

    iconContainer: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },

    icon: {
        fontSize: '35px',
    },

    iconTextTitle: {
        fontFamily: 'bold',
        textTransform: 'uppercase',
        fontSize: '17px',
        textAlign: 'center',
        marginBottom: '10px',
    },

    iconText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        marginBottom: '10px',
        textAlign: 'center',
    },

    textReminder: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    reminderContainer: {
        padding: '10px',
        backgroundColor: '#ffeac7',
        border: '1px solid #ffa200',
        marginBottom: '10px',
    },

    mapGrid: {
        marginTop: '30px',
        border: '1px solid #e6e6e6',
        backgroundColor: '#fff',
    },

    infoContainer: {
        padding: '10px',
    },

    mapContainer: {
        padding: '10px',
    },

    headerContainer: {
        backgroundColor: '#ad230e',
        padding: '10px',
    },

    textHeader: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#fff',
        textAlign: 'center',
    },

    textBoxInfoContainer: {
        display: 'flex',
        marginTop: '10px',
    },

    textboxFirst: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '2px solid #ad230e',
            },

            fontFamily: 'Poppins',
            fontSize: '14px',
            padding: '5px',
            borderRadius: 0,
        },

        width: '100%',
        

        '& .MuiInputBase-input': {
            color: '#000',
            padding: '5px',
            height: '30px',
        },
    },

    submitButton: {
        width: '100%',
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        borderRadius: 0,
        boxShadow: 0,
        backgroundColor: '#ad230e',

        '&:hover': {
            boxShadow: 0,
            backgroundColor: '#7d1a0b',
        },
    },

}
export default style