import React, { useState, useEffect } from "react";
import {Helmet} from "react-helmet";
import { Link, useLocation, useHistory } from "react-router-dom";
// Import Styling
import style from "../styles/ProductDetail";
import "../styles/ProductDetail.css";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import AddressModal from "../components/modals/Address";
// Import Image
// import product from "../image/Product/Sample.jpg";

// Import Icon

// Import Material UI
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
} from "@mui/material";

//redux
import { useSelector } from "react-redux";

//firebase
import { auth, db } from "../utils/firebase";

import {
  doc,
  setDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";



function ProductDetail({isAuth}) {
  
  // const location = useLocation();
  const [addressModal] = useState(false);
  const history = useHistory();
  const [quantity, setQuantity] = useState(1);
  const [errorMessage, setErrorMessage] = useState(false);

  //button
  const buttonViewProduct = (id) => {
    history.push(`productdetail?product=${id}`);
    // dispatch(findProduct(id));
  };
  //get id on url
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let queryy = useQuery();
  let urlId = queryy.get("product");
  // console.log(urlId);

  //redux
  const user = useSelector((state) => state.user);

  //handle
  const handleChange = (e) => {
    setQuantity(e.target.value);
  };
  let products = [user.products.filter((product) => product.id === urlId)];
  const [selectedImg, setSelectedImg] = useState("");

  //button add to cart
  const buttonAddToCart = (id) => {
   
    if (isAuth === false) {
      console.log("user not login");
      history.push("/login");
    } else if (user.carts.find((cart) => cart.ProductId === id.toString())) {
      console.log("already on your cart");
      setErrorMessage(true);
      return null;
    } else {
      console.log(id.toString());
      console.log(quantity);

      auth.onAuthStateChanged((user) => {
        const cartRef = doc(collection(db, "Carts"));
        const data = {
          ProductId: id.toString(),
          CartQty: Number(quantity),
          BuyerUid: user.uid,
          Created: serverTimestamp(),
        };

        setDoc(cartRef, data);
      });
    }
  };
  const buttonBuyNow = () => {
    // if(user.currentUserData[0].UserAddress ===  undefined){
    //   setAddressModal(true);
    // } else{
    //   // history.push(`/checkout?product=${urlId},?quantity=${quantity}`);
    //   history.push({
    //     pathname: '/checkout',
    //     search: `?product=${urlId}`,
    //     state: { quantity:  quantity}
    // });
 
    // }
    history.push("/cart");
  };

  useEffect(() => {
   

    if (selectedImg === "") {
      setSelectedImg(
        user.products
          .filter(function (product) {
            return product.id === urlId;
          })
          .map((product) => product.Image[0])
          .toString()
      );
    }
  }, [selectedImg, user, urlId]);

  return (
    <>

    <Helmet>
    
          <title>{products[0].map((product) => product.ProductName).toString()} </title>
          {/* <meta name="description"  content={products[0].map((product) => product.ProductDesc).toString()} />

      <meta property="og:url" content={location}/>
      <meta property="og:title" content={products[0].map((product) => product.ProductName).toString()}/>
      <meta property="og:description" content={products[0].map((product) => product.ProductDesc).toString()}/>
      <meta property="og:image" content={products[0].map((product) => product.Image).toString()}/> */}
           
           
            </Helmet>

    
      <Box sx={{ backgroundColor: "#f2f2f2" }}>
     

        <Navbar isAuth={isAuth}/>

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>Product Details</Typography>
          <Typography sx={style.breadcrumbsSubtitle}>
            Home / Shop / Product Details
          </Typography>
        </Box>

        {/*Main Container*/}
        <Box sx={style.mainContainer}>
          <Grid container sx={style.mainPaperContainer}>
            {/*Image Container*/}
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              sx={style.productImageGrid}
            >
              <Box sx={style.selectedImageContainer}>
                <Box sx={style.selectedImage}>
                  <img
                    src={selectedImg}
                    alt="Selected"
                    style={{
                      width: "100%",
                      maxHeight: "420px",
                      objectFit: "contain",
                      pointerEvents: "none"
                     
                    }}
                  />
                </Box>
              </Box>

              <Box sx={style.thumbnailContainer}>
                <Box sx={style.basketImageContainer}>
                  {products[0].map((product, index) => (
                    <Box sx={style.imageThumbContainer} key={index}>
                      {product.Image.map((image, index) => (
                        <Box key={index}   onClick={() => setSelectedImg(image)}>
                           <img
                          src={image}
                          alt="selection"
                          style={{
                            width: "100px",
                            maxHeight: "80px",
                            objectFit: "contain",
                            cursor: "pointer",
                            pointerEvents: "none",
                          }}
                        
                        />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>

            <Grid item xs sx={style.productDetail}>
              <Box sx={style.productTitleContainer}>
                <Typography sx={style.prodTitle}>
                  {products[0].map((product) => product.ProductName)}
                </Typography>
              </Box>

              <Box sx={style.productDescriptionContainer}>
                <Typography sx={style.prodDescription}>
                  {products[0].map((product) => product.ProductDesc)}
                </Typography>
              </Box>

              <Box sx={style.prodDescContainer}>
                <Typography sx={style.prodDescLabel}>Availability:</Typography>
                {products[0]
                  .map((product) => product.ProductStock)
                  .toString() === "true" ? (
                  <Typography sx={style.stockText1}>In Stock</Typography>
                ) : (
                  <Typography sx={style.stockText2}>Not Available</Typography>
                )}
              </Box>

              <Box sx={style.prodDescContainer}>
                <Typography sx={style.prodDescLabel}>Price:</Typography>
                <Typography sx={style.priceText}>
                  ₱ {products[0].map((product) => product.ProductPrice)}.00
                </Typography>
              </Box>

              <Box sx={style.prodDescContainer}>
                <Typography sx={style.prodDescLabel}>Quantity:</Typography>
                <Box sx={style.productItemCountDrawerContainer}>
                  <Button
                    variant="outlined"
                    sx={style.addlessButton}
                    onClick={() =>
                      setQuantity(quantity - (quantity > 1 ? 1 : 0))
                    }
                  >
                    -
                  </Button>
                  <TextField
                    sx={style.productQtyDrawerInput}
                    type="number"
                    value={quantity}
                    onChange={handleChange}
                  />
                  <Button
                    variant="outlined"
                    sx={style.addlessButton}
                    onClick={() => setQuantity(quantity + 1)}
                  >
                    +
                  </Button>
                </Box>
              </Box>

              <Box sx={style.prodButtonContainer}>
                <Box>
                  <Button
                    variant="contained"
                    sx={style.addToCartButton}
                    onClick={() =>
                      buttonAddToCart(products[0].map((product) => product.id))
                    }
                  >
                    Add to Cart
                  </Button>
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    sx={style.buyNowButton}
                    // disabled={
                    //   products[0]
                    //     .map((product) => product.ProductStock)
                    //     .toString() === "false"
                    //     ? true
                    //     : false
                    // }
                    disabled
                    onClick={ buttonBuyNow}
                  >
                    Buy Now
                  </Button>
                </Box>
              </Box>
              {errorMessage ? (
                <Box sx={style.alertContainer}>
                  <Typography sx={style.alertText}>
                    This product is already on your cart
                  </Typography>
                </Box>
              ) : null}
              <Box sx={style.alertContainer}>
                <Typography sx={style.alertText}>
                  Shipping fee and other payment will be calculated at checkout.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box sx={style.suggestedContainer}>
            {/*Suggested Products*/}
            <Box sx={style.suggestedOption}>
              <Typography sx={style.titleText}>Suggested Products</Typography>
              <Link to="/shop" style={{ textDecoration: "none" }}>
                <Typography sx={style.textSeeAll}>See All {">>"}</Typography>
              </Link>
            </Box>

            {/*Products*/}
            <Grid container sx={style.mainProductContainer}>
              { user.products.slice(0,5).map((product, i) => (
              <Grid item sx={style.perItem} key={i}>
                <Box>
                  <Card sx={style.cardProduct}>
                    <CardActionArea onClick ={() => buttonViewProduct(product.id)}>
                      <CardMedia
                        component="img"
                        height="180"
                        src={product.Image[0]}
                        alt="product"
                        style={{ pointerEvents: "none"}}
                      />
                      <CardContent sx={{ padding: "8px" }}>
                        <Box sx={style.boxTitleContainer}>
                          <Typography sx={style.productTitle}>
                            {product.ProductName}
                          </Typography>
                        </Box>

                        <Box sx={style.boxDescriptionContainer}>
                          <Typography sx={style.productDescription}>
                          {product.ProductDesc}
                          </Typography>
                        </Box>

                        <Box sx={style.boxPriceContainer}>
                          <Typography sx={style.productPrice}>
                          ₱ {product.ProductPrice}.00
                          </Typography>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Box>
              </Grid>
)) }
            
               
            </Grid>
          </Box>
        </Box>
        <AddressModal open={addressModal} />
        <Footer />
      </Box>
      </>
  );
}

export default ProductDetail;
