import React from "react";
import {Helmet} from "react-helmet";
// Import Style
import style from "../styles/NewsPost";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icon
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// Import Image
// import product from "../image/Product/Sample.jpg";
// import jomar from "../image/jomar.jpg";

import { useLocation } from "react-router-dom";

// Import Material UI
import {
  Box,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";

//redux
import { useSelector } from "react-redux";
//date fns 
import { format } from 'date-fns';

function News({ isAuth }) {
const user = useSelector((state) => state.user);
  //get id on url
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let queryy = useQuery();
  let urlId = queryy.get("newspost");
  // console.log(urlId);

  return (

      <Box sx={{ backgroundColor: "#f2f2f2" }}>
      <Helmet>
             <title>DC Monorack -  {user.newsBlogs.filter((news) => (news.id === urlId)).map((news) => (news.Title)).toString()} </title>
            <meta name="DC Monorack News Blogs" 
            content={user.newsBlogs.filter((news) => (news.id === urlId)).map((news) => (news.Desc)).toString()} />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>
        <Navbar isAuth={isAuth} />

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>News {"&"} Blogs</Typography>
          <Typography sx={style.breadcrumbsSubtitle}>
            Latest News and Happenings Inside DC Monorack
          </Typography>
        </Box>

        {/* Main Container */}
        <Box sx={style.mainContainer}>
          {/* Main Grid */}
          <Grid container>
            {/* Grid ng Latest */}
            <Grid item xs={12} sm={12} md={12} lg={4} sx={style.mainLeftGrid}>
              <Box sx={style.gridLeftContainer}>
                <Box>
                  <Typography sx={style.gridLeftTitle}>
                    Latest News {"&"} Blogs
                  </Typography>
                </Box>

                {/* Per Post Card */}
                { user.newsBlogs.slice(0,4).map((news, i) => (
                <Card sx={style.cardPost}>
                  <CardMedia
                    component="img"
                    image={news.Image}
                    sx={{ width: 80, display: "flex", alignItems: "center", pointerEvents: "none"}}
                  />

                  <CardContent sx={style.cardContent}>
                    <Box>
                      <Typography sx={style.postTitle}>
                        {news.Title}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={style.postContent}>
                    {news.Desc}
                      </Typography>
                    </Box>

                    <Box sx={style.postDateContainer}>
                      <CalendarMonthIcon sx={style.calendarIcon} />
                      <Typography sx={style.postDate}>{format(new Date((news.Created.seconds*1000)), 'MM/dd/yyyy')}</Typography>
                    </Box>
                  </CardContent>
                </Card>
                ))}
              </Box>
            </Grid>

            {/* Grid ng Main News - Container */}
            <Grid item xs={12} sm={12} md={12} lg={8} sx={style.mainRightGrid}>
              <Box sx={{ width: "100%" }}>
                {/* Main Per Article */}
                <Box sx={style.articlePictureContainer}>
                  <img
                    alt="article_picture"
                    src={ user.newsBlogs.filter((news) => (news.id === urlId)).map((news) => (news.Image)).toString()}
                    style={{
                      width: "100%",
                      height: "60vh",
                      objectFit: "cover",
                      pointerEvents: "none"
                    }}
                  />
                </Box>

                {/* Article Content */}
                <Box sx={style.articleTitleContainer}>
                  <Typography sx={style.articleTitle}>
                  {user.newsBlogs.filter((news) => (news.id === urlId)).map((news) => (news.Title)).toString()}
                  </Typography>
                </Box>

                <Box sx={style.articleContentContainer}>
                  <Typography sx={style.articleContent}>
                  {user.newsBlogs.filter((news) => (news.id === urlId)).map((news) => (news.Desc)).toString()}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Footer />
      </Box>

  );
}

export default News;
