import React,{ useState, useLayoutEffect } from "react";
import {Helmet} from "react-helmet";
// Import Styling
import style from "../styles/Cart";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icon
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";

// Import Image
// import product from "../image/Product/Sample.jpg";
// import hondacb150 from "../image/Motorcycles/HONDA_CB_150.png";
// import bryce from "../image/Developers/Bryce.jpg";

import AddressModal from "../components/modals/Address";

// Import Material UI
import {
  Box,
  Typography,
  Grid,
  Button,
  TextField,

} from "@mui/material";
import { useHistory } from "react-router-dom";
//redux
import { useSelector } from "react-redux";

//firebase
import {

  doc,
  updateDoc,
  serverTimestamp,
  increment,
  deleteDoc,

} from "firebase/firestore";

import { db} from "../utils/firebase";

const Cart = ({isAuth}) => {

  const [message, setMessage] = useState("");
  const [addressModal, setAddressModal] = useState(false);
  const [total, setTotal] = useState(0);
    const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const history = useHistory();
  const user = useSelector((state) => state.user);

//button
  const removeButton = (id) => {
    deleteDoc(doc(db, "Carts", id));
  };
  const incButton = (id) => {
    console.log("increment", id);
    const docRef = doc(db, "Carts", id);
    const payload = { CartQty: increment(1), UpdateCreated: serverTimestamp() };
    updateDoc(docRef, payload);
  };
  const decButton = (id) => {
    console.log("decrement", id);
    const docRef = doc(db, "Carts", id);
    const payload = {
      CartQty: increment(-1),
      UpdateCreated: serverTimestamp(),
    };
    updateDoc(docRef, payload);
  };

  const buttonBuyNow = () => {
    if(user.currentUserData[0].UserAddress ===  undefined){
      setAddressModal(true);
    }else{
      history.push({
        pathname: '/checkout',
        // search: `?product=${urlId}`,
        state: { message:  message}
    });
    }
  };

  useLayoutEffect(() => {
   
    var rows = [0];
    let pro = 0;
    for (let i = 0; i < user.carts.length; i++) {
      pro =
        user.products.filter(
          (product, key) =>
            product.id === user.carts.map((cart) => cart.ProductId)[i]
        )[0].ProductPrice * user.carts.map((cart) => cart.CartQty)[i];
      console.log(pro);
      rows.push(pro);
    }
    setTotal(rows.reduce(reducer));

    // console.log("row",rows.reduce(reducer))
  }, [total, user]);
  return (

      <Box sx={{ backgroundColor: "#f2f2f2" }}>
      <Helmet>
             <title>DC Monorack - Cart </title>
            <meta name="DC Monorack Cart " 
            content="Add items from the shop" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

        <Navbar isAuth/>

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>Cart</Typography>
          <Typography sx={style.breadcrumbsSubtitle}>Home / Cart</Typography>
        </Box>

        {/*If there's nothing in the cart*/}
        {user.carts.length === 0 ? (
          <Box sx={style.nothingContainer}>
            <Box sx={style.iconContainer}>
              <AddShoppingCartIcon sx={style.shoppingIconNone} />
            </Box>

            <Box sx={style.textContainer}>
              <Typography sx={style.boldText}>No items in your cart</Typography>
              <Typography sx={style.subText}>
                Add items from the shop
              </Typography>
            </Box>

            <Box sx={style.buttonContainer}>
              <Button variant="contained" sx={style.shopButton}>
                Go to Shop
              </Button>
            </Box>
          </Box>
        ) : null}

        {/*Main Cart*/}
        {user.carts.length > 0 ? (
          <Box sx={style.mainContainer}>
            <Grid container sx={style.cartMainContainer}>
              {/*Cart Part*/}
              <Grid item xs>
                <Box>
                  <Typography sx={style.titleTextCart}>Products</Typography>
                </Box>

                {/*Product*/}
                {user.carts.map((cart, key) => (
                  <Box sx={style.productContainer} key={cart.id}>
                    <Box sx={style.removeButtonContainer}>
                      <Button
                        variant="contained"
                        sx={style.removeButton}
                        onClick={() => removeButton(cart.id)}
                      >
                        <CloseIcon sx={style.closeIcon} />
                      </Button>
                    </Box>

                    <Grid container>
                      {/*Product Picture*/}
                      <Grid
                        item
                        sx={style.productPictureGrid}
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                      >
                        <Box sx={style.productImageContainer}>
                          <img
                            src={
                              user.products.filter(
                                (product) => product.id === cart.ProductId
                              )[0].Image[0]
                            }
                            alt="product"
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                      </Grid>

                      {/*Product Description*/}
                      <Grid item sx={style.productDescriptionGrid} xs>
                        <Box sx={style.descGridContent}>
                          <Box sx={style.boxProd}>
                            <Typography sx={style.prodText}>
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductName
                              }
                            </Typography>
                          </Box>

                          <Box sx={style.boxDesc}>
                            <Typography sx={style.descText}>
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductDesc
                              }
                            </Typography>
                          </Box>

                          <Box sx={style.boxPrice}>
                            <Typography sx={style.priceLabel}>
                              Unit Price:
                            </Typography>
                            <Typography sx={style.priceText}>
                              ₱{" "}
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductPrice
                              }{" "}
                              .00
                            </Typography>
                          </Box>

                          <Box sx={style.boxQty}>
                            <Box>
                              <Typography sx={style.priceLabel}>
                                Quantity:
                              </Typography>
                            </Box>

                            <Box sx={style.productItemCountDrawerContainer}>
                              <Button
                                variant="outlined"
                                sx={style.addlessButton}
                                onClick={() => decButton(cart.id)}
                                disabled={cart.CartQty === 1 ? true : false}
                              >
                                -
                              </Button>
                              <TextField
                                sx={style.productQtyDrawerInput}
                                defaultValue={cart.CartQty}
                              />
                              <Button
                                variant="outlined"
                                sx={style.addlessButton}
                                onClick={() => incButton(cart.id)}
                              >
                                +
                              </Button>
                            </Box>
                          </Box>

                          <Box sx={style.boxPrice}>
                            <Typography sx={style.priceLabel}>
                              Total Price:
                            </Typography>
                            <Typography sx={style.priceText}>
                              ₱{" "}
                              {user.products.filter(
                                (product) => product.id === cart.ProductId
                              )[0].ProductPrice * cart.CartQty}{" "}
                              .00
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                <Box sx={style.cartControlContainer}>
                  <Button variant="contained" sx={style.continueShopping} onClick={() => {history.push("/shop")}}>
                    Continue Shopping
                  </Button>
                  <Button variant="contained" sx={style.refreshButton}>
                    Refresh Cart
                  </Button>
                </Box>
              </Grid>

              {/*Order Summary*/}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box>
                  <Typography sx={style.titleTextCart}>
                    Order Summary
                  </Typography>
                </Box>

                <Box sx={style.secondGridContainer}>
                  <Box sx={style.summaryBox}>
                    <Box sx={style.priceSummaryContainer}>
                      <Typography sx={style.summaryLabel}>
                        Order Subtotal Price:
                      </Typography>
                      <Typography sx={style.summaryPrice}>
                        ₱ {total}.00
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={style.messageBoxContainer}>
                    <Typography sx={style.messageLabel}>
                      Message/Instructions to Seller
                    </Typography>

                    <TextField
                      multiline
                      rows={5}
                      placeholder="Write your message here ..."
                      variant="outlined"
                      sx={style.messageBox}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Box>

                  <Box sx={style.checkoutContainer} onClick={buttonBuyNow}>
                    <Button variant="contained" sx={style.checkoutButton}>
                      Proceed to Checkout
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : null}

<AddressModal open={addressModal} />
        <Footer />
      </Box>
   
  );
}

export default Cart;
