import React from 'react'
import {Helmet} from "react-helmet";
import style from '../styles/Home'


import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

//  Import Image
import install from '../image/slides/install.jpg'
import clients from '../image/slides/clients.jpg'
// import product from '../image/Product/Sample.jpg'
import euro from '../image/Brand/euro.jpg'
import honda from '../image/Brand/honda.jpg'
import kawasaki from '../image/Brand/kawasaki.jpg'
import keeway from '../image/Brand/keeway.jpg'
import kymco from '../image/Brand/kymco.jpg'
import rusi from '../image/Brand/rusi.jpg'
import suzuki from '../image/Brand/suzuki.jpg'
import sym from '../image/Brand/sym.jpg'
import yamaha from '../image/Brand/yamaha.jpg'
import cus1 from '../image/customers/cus1.jpg'
import cus2 from '../image/customers/cus2.jpg'
import cus3 from '../image/customers/cus3.jpg'
import cus4 from '../image/customers/cus4.jpg'



// Importing Awesome Slider
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import '../styles/slider.css'
import withAutoplay from 'react-awesome-slider/dist/autoplay';



// Import Material UIs
import {
    Box,
    Typography,
    Grid,
    Button,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,

} from '@mui/material';

export default function Home({ isAuth }) {

    const user = useSelector((state) => state.user);
    const history = useHistory();
    const AutoplaySlider = withAutoplay(AwesomeSlider);

      //button
      const buttonViewProduct = (id) => {
        history.push(`productdetail?product=${id}`);
        // dispatch(findProduct(id));
      };

    return (

        <Box>
             <Helmet>
             <title>DC Monorack - Home </title>
            <meta name="DC Monorack Home " 
            content="Lifetime warranty, Quality Motorcycle Brackets Guaranteed And Lifetime Warranty. What are you waiting for? Avail Now!" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

            <Navbar isAuth={isAuth}/>

            {/* Main Container */}
            <Box sx={style.mainContainer}>

                {/* For Banner */}
                <Box sx={style.banner}>

                    <AutoplaySlider
                        className='aws-btn'
                        bullets={false}
                        play={true}
                        interval={20000}
                    >

                        <Box>
                            <img
                                alt='Images'
                                src={clients}
                                style={{ pointerEvents: "none"}}
                            />

                            <Box sx={style.slideContent}>
                                <Typography sx={style.sliderBig}>Lifetime warranty</Typography>
                                <Typography sx={style.sliderMedium}>Quality Motorcycle Brackets Guaranteed And Lifetime Warranty. What are you waiting for? Avail Now!</Typography>

                                <Box sx={style.sliderButtonContainer}>
                                    <Button variant="contained" sx={style.sliderBuyButton} onClick={() => history.push("/shop")}>Buy Now</Button>

                                    <Button variant="outlined" sx={style.sliderContactButton}onClick={() => history.push("/contact")}>Contact Us</Button>
                                </Box>

                            </Box>
                        </Box>

                        <Box>
                            <img
                                alt='Images'
                                src={install}
                                style={{ pointerEvents: "none"}}
                            />
                            <Box sx={style.slideContent}>
                                <Typography sx={style.sliderBig}>High-Quality Brackets</Typography>
                                <Typography sx={style.sliderMedium}>Made with High Grade Steel and other Top-Quality Materials.</Typography>

                                <Box sx={style.sliderButtonContainer}>
                                    <Button variant="contained" sx={style.sliderBuyButton} onClick={() => history.push("/shop")}>Buy Now</Button>

                                    <Button variant="outlined" sx={style.sliderContactButton} onClick={() => history.push("/contact")}>Contact Us</Button>
                                </Box>
                            </Box>
                        </Box>

                    </AutoplaySlider>
                </Box>

                {/* Available Motorcycle Brands */}
                <Box sx={style.featuredBrandBox}>
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.homeTitle}>Featured Motorcycle Brands</Typography>
                    </Box>



                    <Grid container sx={style.brandGrid}>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Honda"
                                    src={honda}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Yamaha"
                                    src={yamaha}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Kawasaki"
                                    src={kawasaki}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Suzuki"
                                    src={suzuki}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Sym"
                                    src={sym}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Rusi"
                                    src={rusi}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Euro"
                                    src={euro}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Keeway"
                                    src={keeway}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                        {/* Per Brand */}
                        <Grid item>
                            <Box sx={style.brandImageContainer}>
                                <img
                                    alt="Kymco"
                                    src={kymco}
                                    style={{
                                        width: '100px',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                        </Grid>

                    </Grid>
                </Box>

                {/* Products */}
                <Box sx={style.mainProductBox}>
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.homeTitle}>Available Products</Typography>
                    </Box>

                    {/* Card Products */}
                    <Grid container sx={style.productMainContainer}>

                        {/* Product */}
                        { user.products.slice(0,10).map((product, i) => (
                        <Grid item sx={style.perItem} key={i}>
                            <Card sx={style.cardProduct} onClick={() => buttonViewProduct(product.id)}>
                                <CardActionArea>
                                    <CardMedia
                                        component="img"
                                        height="180"
                                        src={product.Image[0]}
                                        alt="product"
                                        style={{ pointerEvents: "none"}}
                                    />

                                    <CardContent sx={{ padding: '8px' }}>
                                        <Box sx={style.boxTitleContainer}>
                                            <Typography sx={style.productTitle}>{product.ProductName}</Typography>
                                        </Box>

                                        <Box sx={style.boxDescriptionContainer}>
                                            <Typography sx={style.productDescription}>
                                            {product.ProductDesc}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography sx={style.productPrice}>
                                                ₱ {product.ProductPrice}.00
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid> ))}

                        

                    </Grid>
                </Box>

            </Box>

            {/*BreadCrumbs Deals*/}
            <Box sx={style.dealsBreadcrumbs}>
                <Box>
                    <Typography sx={style.dealBigText}>Check Our Products</Typography>
                </Box>

                <Box sx={style.dealSubText1Container}>
                    <Typography sx={style.dealSubText1}>DC Monorack</Typography>
                </Box>

                <Box sx={style.dealSubText2Container}>
                    <Typography sx={style.dealSubText2}>Providing high-quality motorcycle brackets since 2017. Hurry! Avail our products now!</Typography>
                </Box>

                <Box>
                    <Button variant="contained" sx={style.goToShopButton} onClick={() => history.push("/shop")}>
                        Go To Shop
                    </Button>
                </Box>
            </Box>


            {/* Satisfied Customers */}
            <Box sx={style.mainContainer}>
                <Box sx={style.titleContainer}>
                    <Typography sx={style.homeTitle}>Satisfied Customers</Typography>
                </Box>

                <Grid container sx={style.imageGridGallery}>

                    {/* Per Customer */}
                    <Grid item>
                        <Box sx={style.imageContainer}>
                            <img
                                alt="cutomer"
                                src={cus1}
                                style={{
                                    width: '250px',
                                    height: '250px',
                                    objectFit: 'cover',
                                    pointerEvents: "none"
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Per Customer */}
                    <Grid item>
                        <Box sx={style.imageContainer}>
                            <img
                                alt="cutomer"
                                src={cus2}
                                style={{
                                    width: '250px',
                                    height: '250px',
                                    objectFit: 'cover',
                                    pointerEvents: "none"
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Per Customer */}
                    <Grid item>
                        <Box sx={style.imageContainer}>
                            <img
                                alt="cutomer"
                                src={cus3}
                                style={{
                                    width: '250px',
                                    height: '250px',
                                    objectFit: 'cover',
                                    pointerEvents: "none"
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* Per Customer */}
                    <Grid item>
                        <Box sx={style.imageContainer}>
                            <img
                                alt="cutomer"
                                src={cus4}
                                style={{
                                    width: '250px',
                                    height: '250px',
                                    objectFit: 'cover',
                                    pointerEvents: "none"
                                }}
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box>

            <Footer />
        </Box>
    )
}
