import React, { useRef } from 'react';
import {Helmet} from "react-helmet";
// Import Styling
import style from '../styles/Contact';

// Import other Pages
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// Import Image


// Import Icon
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import PlaceIcon from '@mui/icons-material/Place'

// Import Material UI
import {
    Box,
    Typography,
    Grid,
    Button,
    TextField,
} from '@mui/material';

import emailjs from 'emailjs-com';
function Contact({isAuth}) {

    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_llj999r', 'template_8jcd12k', form.current, 'user_pUbJLadImLvwIkkXJUp5J')
      .then((result) => {
          console.log(result.text);
          alert("Successfully send");
      }, (error) => {
          console.log(error.text);
      });
      form.current.reset();
  };
    return (

            <Box sx={{ backgroundColor: '#f2f2f2', }}>

            <Helmet>
             <title>DC Monorack - Contact Us </title>
            <meta name="DC Monorack Contact Us " 
            content="Questions and Inquiries? You may directly contact us" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

                <Navbar isAuth={isAuth}/>

                {/*BreadCrumbs*/}
                <Box sx={style.breadcrumbsContainer}>
                    <Typography sx={style.breadcrumbsTitle}>Contact Us</Typography>
                    <Typography sx={style.breadcrumbsSubtitle}>Questions and Inquiries? You may directly contact us</Typography>
                </Box>

                <Box sx={style.mainContainer}>
                    <Box sx={style.contactMainBox}>
                        <Typography sx={style.textInstruction}>
                            Please contact us for any reason, and we will do our best to meet your needs or assist you in making the most of our website. You can reach us by phone or through our online form on this page.
                        </Typography>
                    </Box>

                    <Box sx={style.reminderContainer}>
                        <Typography sx={style.textReminder}>
                            Send us an email! We will do our best to respond as soon as possible. If this is an emergency, please contact us during our normal business hours for a quick response.
                        </Typography>
                    </Box>

                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Box sx={style.boxInfoMain}>
                                <Box sx={style.iconContainer}>
                                    <EmailIcon sx={style.icon} />
                                </Box>
                                <Box sx={style.textBoxContainer}>
                                    <Typography sx={style.iconTextTitle}>
                                        Send us an Email
                                    </Typography>
                                    <Typography sx={style.iconText}>
                                        jomarsuriodelacruz@gmail.com
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={style.boxInfoMain}>
                                <Box sx={style.iconContainer}>
                                    <LocalPhoneIcon sx={style.icon} />
                                </Box>
                                <Box sx={style.textBoxContainer}>
                                    <Typography sx={style.iconTextTitle}>
                                        Give us a Call
                                    </Typography>
                                    <Typography sx={style.iconText}>
                                        {"+(63)"} 935 374 4312
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Box sx={style.boxInfoMain}>
                                <Box sx={style.iconContainer}>
                                    <PlaceIcon sx={style.icon} />
                                </Box>
                                <Box sx={style.textBoxContainer}>
                                    <Typography sx={style.iconTextTitle}>
                                        Visit Us
                                    </Typography>
                                    <Typography sx={style.iconText}>
                                        No. 58, Purok 3, Bagong Silang, Plaridel, Bulacan
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container sx={style.mapGrid}>
                        <Grid item xs={12} md={6}>
                        <form ref={form} onSubmit={sendEmail}>
                            <Box sx={style.infoContainer}>
                                <Box sx={style.headerContainer}>
                                    <Typography sx={style.textHeader}>Questions and Other Inquiries? Leave us a message!</Typography>
                                </Box>

                                <Box sx={style.textBoxInfoContainer}>
                                    <TextField placeholder='Name' sx={style.textboxFirst}
                                      type="text" name="from_name" id="from_name"
                                    />
                                </Box>

                                <Box sx={style.textBoxInfoContainer}>
                                    <TextField placeholder='Contact Number' sx={style.textboxFirst} />
                                </Box>

                                <Box sx={style.textBoxInfoContainer}>
                                    <TextField placeholder='Email Address' sx={style.textboxFirst} 
                                      type="email" name="user_email"
                                    />
                                </Box>

                                <Box sx={style.textBoxInfoContainer}>
                                    <TextField
                                        id="message"
                                        type="text"
                                        name="message"
                                        multiline
                                        rows={10}
                                        placeholder="Write your message here ..."
                                        sx={style.textboxFirst} />
                                </Box>

                                <Box sx={style.textBoxInfoContainer}>
                                    <Button variant="contained" sx={style.submitButton} type="submit" value="Send">Submit</Button>
                                    {/* <input type="submit" value="Send"  style={style.submitButton}/> */}
                                </Box>
                            </Box>
                            </form>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Box sx={style.mapContainer}>
                                <Box>
                                    <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15424.720734551378!2d120.8730786750795!3d14.871199723145896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x339653e10fb7cb51%3A0xf9a8a08d69ac9524!2sQ.K.D.C%20STEEL%20FABRICATION(DCMONORACK)!5e0!3m2!1sfil!2sph!4v1651576668730!5m2!1sfil!2sph" width="600" height="500" style={{ border: 0 }} loading="lazy"></iframe>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>




                <Footer />
            </Box>
     
    )
}

export default Contact