import React, { useState, useLayoutEffect } from "react";

// Import Styling
import style from "../styles/Cart";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icon
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";


// Import Image
// import product from "../image/Product/Sample.jpg";
// import hondacb150 from "../image/Motorcycles/HONDA_CB_150.png";
// import bryce from "../image/Developers/Bryce.jpg";

import AddressModal from "../components/modals/Address";

// Import Material UI
import { Box, Typography, Grid, Button, TextField } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
//redux
import { useSelector } from "react-redux";

//firebase
import {
  doc,
  updateDoc,
  serverTimestamp,
  increment,
  deleteDoc,

} from "firebase/firestore";

import { db } from "../utils/firebase";
import { addDoc, collection } from "firebase/firestore";
const Checkout = ({ isAuth }) => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
//   const [message, setMessage] = useState("");
  const [addressModal] = useState(false);
  const [total, setTotal] = useState(0);
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const history = useHistory();
  const user = useSelector((state) => state.user);

  //get id on url
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let queryy = useQuery();
  let urlId = queryy.get("product");
//   let quantity = queryy.get("quantity");
  // console.log(urlId);
  //button
//   const removeButton = (id) => {
//     deleteDoc(doc(db, "Carts", id));
//   };
//   const incButton = (id) => {
//     console.log("increment", id);
//     const docRef = doc(db, "Carts", id);
//     const payload = { CartQty: increment(1), UpdateCreated: serverTimestamp() };
//     updateDoc(docRef, payload);
//   };
  const decButton = (id) => {
    console.log("decrement", id);
    const docRef = doc(db, "Carts", id);
    const payload = {
      CartQty: increment(-1),
      UpdateCreated: serverTimestamp(),
    };
    updateDoc(docRef, payload);
  };

  const buttonCOD = () => {
    console.log("Cash On Delivery");
    var years = new Date().getFullYear();
    var months = new Date().getMonth();
    var text = "" + months + years;
    for (let i = 0; i < 10; i++) {
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }


        user.carts.map(async (cart, key) =>
             await addDoc(collection(db, "Orders"), {
                OrderId: "DCMonorack" + text,
                ProductId:user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].id,
                ProductImage:  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].Image[0],
                ProductName:  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].ProductName,
                  ProductDesc:  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].ProductDesc,
                ProductPrice:  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].ProductPrice,
                SellerUid:  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].SellerUid,
                ProductQty: cart.CartQty,
                BuyerUid: user.currentUserData[0].UserUid,
                BuyerMessage: location.state.message,
                BuyerName: user.currentUserData[0].UserName,
                BuyerAddress: user.currentUserData[0].UserAddress,
                BuyerContactNumber: user.currentUserData[0].ContactNumber,
                OrderStatus: "Pending",
                SellerAccept: false,
                Total: Number(total),
                Payment: "COD",
                CreatedAt: serverTimestamp(),
              })
          );
    
          user.carts.map(async (cart) => await deleteDoc(doc(db, "Carts", cart.id)));

    
  };

  useLayoutEffect(() => {
    var rows = [0];
    let pro = 0;
    for (let i = 0; i < user.carts.length; i++) {
      pro =
        user.products.filter(
          (product, key) =>
            product.id === user.carts.map((cart) => cart.ProductId)[i]
        )[0].ProductPrice * user.carts.map((cart) => cart.CartQty)[i];
      console.log(pro);
      rows.push(pro);
    }
    setTotal(rows.reduce(reducer));

    if (urlId === undefined) {
      setProducts(user.carts);
    } else {
    }
    // console.log(urlId);
    // console.log(location.state.quantity);
    // console.log("row",rows.reduce(reducer))
  }, [total, user, products, location, urlId]);
  return (
 
      <Box sx={{ backgroundColor: "#f2f2f2" }}>
        <Navbar isAuth />

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>Checkout</Typography>
        </Box>

        {/*If there's nothing in the cart*/}
        {user.carts.length === 0 ? (
          <Box sx={style.nothingContainer}>
            <Box sx={style.iconContainer}>
              <AddShoppingCartIcon sx={style.shoppingIconNone} />
            </Box>

            <Box sx={style.textContainer}>
              <Typography sx={style.boldText}>No items in your cart</Typography>
              <Typography sx={style.subText}>
                Add items from the shop
              </Typography>
            </Box>

            <Box sx={style.buttonContainer}>
              <Button variant="contained" sx={style.shopButton}>
                Go to Shop
              </Button>
            </Box>
          </Box>
        ) : null}

        {/*Main Cart*/}
        {user.carts.length > 0 ? (
          <Box sx={style.mainContainer}>
            <Grid container sx={style.cartMainContainer}>
              {/*Cart Part*/}
              <Grid item xs>
                <Box>
                  <Typography sx={style.titleTextCart}>Products</Typography>
                </Box>

                {/*Product*/}
                {user.carts.map((cart, key) => (
                  <Box sx={style.productContainer} key={cart.id}>
                    <Grid container>
                      {/*Product Picture*/}
                      <Grid
                        item
                        sx={style.productPictureGrid}
                        xs={12}
                        sm={12}
                        md={4}
                        lg={4}
                      >
                        <Box sx={style.productImageContainer}>
                          <img
                            src={
                              user.products.filter(
                                (product) => product.id === cart.ProductId
                              )[0].Image[0]
                            }
                            alt="product"
                            style={{
                              width: "150px",
                              height: "150px",
                              objectFit: "contain",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                      </Grid>

                      {/*Product Description*/}
                      <Grid item sx={style.productDescriptionGrid} xs>
                        <Box sx={style.descGridContent}>
                          <Box sx={style.boxProd}>
                            <Typography sx={style.prodText}>
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductName
                              }
                            </Typography>
                          </Box>

                          <Box sx={style.boxDesc}>
                            <Typography sx={style.descText}>
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductDesc
                              }
                            </Typography>
                          </Box>

                          <Box sx={style.boxPrice}>
                            <Typography sx={style.priceLabel}>
                              Unit Price:
                            </Typography>
                            <Typography sx={style.priceText}>
                              ₱{" "}
                              {
                                user.products.filter(
                                  (product) => product.id === cart.ProductId
                                )[0].ProductPrice
                              }{" "}
                              .00
                            </Typography>
                          </Box>

                          <Box sx={style.boxQty}>
                            <Box>
                              <Typography sx={style.priceLabel}>
                                Quantity:
                              </Typography>
                            </Box>

                            <Box sx={style.productItemCountDrawerContainer}>
                              <Button
                                variant="outlined"
                                sx={style.addlessButton}
                                onClick={() => decButton(cart.id)}
                                disabled
                              >
                                -
                              </Button>
                              <TextField
                                sx={style.productQtyDrawerInput}
                                disabled
                                // defaultValue={location.state.quantity === undefined ? cart.CartQty : location.state.quantity}
                                defaultValue={cart.CartQty}
                              />
                              <Button
                                variant="outlined"
                                sx={style.addlessButton}
                                disabled
                              >
                                +
                              </Button>
                            </Box>
                          </Box>

                          <Box sx={style.boxPrice}>
                            <Typography sx={style.priceLabel}>
                              Total Price:
                            </Typography>
                            <Typography sx={style.priceText}>
                              ₱{" "}
                              {user.products.filter(
                                (product) => product.id === cart.ProductId
                              )[0].ProductPrice * cart.CartQty}{" "}
                              .00
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                <Box sx={style.cartControlContainer}>
                  <Button
                    variant="contained"
                    sx={style.continueShopping}
                    onClick={() => {
                      history.push("/shop");
                    }}
                  >
                    Continue Shopping
                  </Button>
                  {/* <Button variant="contained" sx={style.refreshButton}>
                    Refresh Cart
                  </Button> */}
                </Box>
              </Grid>

              {/*Order Summary*/}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box>
                  <Typography sx={style.titleTextCart}>
                    Order Summary
                  </Typography>
                </Box>

                <Box sx={style.secondGridContainer}>
                  <Box sx={style.summaryBox}>
                    <Box sx={style.priceSummaryContainer}>
                      <Typography sx={style.summaryLabel}>
                        Order Subtotal Price:
                      </Typography>
                      <Typography sx={style.summaryPrice}>
                        ₱ {total}.00
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={style.messageBoxContainer}>
                    <Typography sx={style.messageLabel}>
                      Message/Instructions to Seller
                    </Typography>

                    <TextField
                      multiline
                      rows={5}
                      placeholder="Write your message here ..."
                      variant="outlined"
                      sx={style.messageBox}
                      value={location.state.message}
                      disabled
                    />
                  </Box>

                  <Box sx={style.checkoutContainer} onClick={buttonCOD}>
                    <Button variant="contained" sx={style.checkoutButton}>
                      Cash on Delivery
                    </Button>
                  </Box>
                  <Box sx={style.checkoutContainer}>
                    {/* <a
                      data-amount={total}
                      amount={total}
                      data-fee="0"
                      data-expiry="6"
                      data-description="Payment for services rendered"
                      data-href="https://getpaid.gcash.com/paynow"
                      data-public-key="pk_f44fbcbb523aea0f4389ff7fd26323b1"
                      onclick="this.href = this.getAttribute('data-href')+'?public_key='+this.getAttribute('data-public-key')+'&amp;amount='+this.getAttribute('data-amount')+'&amp;fee='+this.getAttribute('data-fee')+'&amp;expiry='+this.getAttribute('data-expiry')+'&amp;description='+this.getAttribute('data-description');"
                      href="https://getpaid.gcash.com/paynow?public_key=pk_f44fbcbb523aea0f4389ff7fd26323b1&amp;amount&amp;fee=0&amp;expiry=6&amp;description=Payment for services rendered"
                      target="_blank"
                      class="x-getpaid-button"
                    >
                      <img src="https://getpaid.gcash.com/assets/img/paynow.png" />
                    </a> */}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        <AddressModal open={addressModal} />
        <Footer />
      </Box>
 
  );
};

export default Checkout;
