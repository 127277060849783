const style = {
    footerParentBox: {
        backgroundColor: '#1a1a1a',
        display: 'flex',
        justifyContent: 'center',
        padding: '80px 0px 90px 0px',
    },

    footerMainContainer: {
        justifyContent: 'center',
        width: '95%',
    },

    footerColumnLogo: {
        width: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
        },

        marginRight: {
            xs: '0px',
            sm: '0px',
            md: '50px',
            lg: '50px',
        },

        justifyContent: 'center',
    },

    textFooterLogo: {
        textAlign: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'Left',
        },
    },

    footerColumn: {
        width: '190px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
        },
    },

    footerLogoDescription: {
        color: '#bfbfbf',
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '25px',
    },

    footerTitle: {
        fontFamily: 'Bold',
        fontSize: '23px',
        lineHeight: '55px',
        color: '#dedede',
    },

    footerContent: {
        fontFamily: 'Poppins',
        lineHeight: '30px',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#bfbfbf',

        "&:hover": {
            color: '#fff',
        },
    },

    socmedLink: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'flex-start',
        },
    },

    socmedContainer: {
        border: '0.01em solid #c9c9c9',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '2px',
        transition: 'all ease 0.5s',
        color: '#9e9e9e',
        cursor: 'pointer',
        "&: hover": {
            backgroundColor: '#ad230e',
            border: '0.01em solid #ad230e',
            color: '#fff',
        },
    },

    socmed: {
        fontSize: '19px',
    },

    footerBoxCreditPayment: {
        display: 'flex',
        backgroundColor: '#1a1a1a',
        padding: '10px 0px 10px 0px',
        justifyContent: 'center',
        borderTop: '1px solid #383838',
    },

    footerCreditPayment: {
        width: '95%',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'space-between',
        },
        alignItems: 'center',
    },

    creditTextContainer: {
        textAlign: {
            xs: 'center',
            sm: 'center',
            md: 'left',
        },
    },

    creditText: {
        fontFamily: 'Poppins',
        color: '#fff',
        fontSize: '14px',
    },

    paymentImage: {
        display: 'flex',
    },

    imageBox: {
        display: 'flex',
        alignItems: 'center',
    },
}
export default style;