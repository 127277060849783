import React, { useState, useEffect } from "react";
import {
  regions,
  provinces,
  cities,
  barangays,
} from "select-philippines-address";
import style from "../../styles/Profile";
import {
  Box,
  Modal,
  TextField,
  Typography,
  Button,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
} from "@mui/material";

// Import Icon
import SettingsIcon from "@mui/icons-material/Settings";
//redux
import { useSelector } from "react-redux";

//firebase
import { db } from "../../utils/firebase";
import { doc, updateDoc } from "firebase/firestore";
export default function Address({ open, setOpen }) {
    const user = useSelector((state) => state.user);
    const [street, setStreet] = useState("");
    const [regionData, setRegion] = useState([]);
    const [provinceData, setProvince] = useState([]);
    const [cityData, setCity] = useState([]);
    const [barangayData, setBarangay] = useState([]);

    const [regionAddr, setRegionAddr] = useState("");
    const [provinceAddr, setProvinceAddr] = useState("");
    const [cityAddr, setCityAddr] = useState("");
    const [barangayAddr, setBarangayAddr] = useState("");

   
    const region = () => {
        regions().then(response => {
            setRegion(response);
        });
    }

    const province = (e) => {
        setRegionAddr(e.target.selectedOptions[0].text);
        provinces(e.target.value).then(response => {
            setProvince(response);
            setCity([]);
            setBarangay([]);
        });
    }

    const city = (e) => {
        setProvinceAddr(e.target.selectedOptions[0].text);
        cities(e.target.value).then(response => {
            setCity(response);
        });
    }

    const barangay = (e) => {
        setCityAddr(e.target.selectedOptions[0].text);
        barangays(e.target.value).then(response => {
            setBarangay(response);
        });
    }

    const brgy = (e) => {
        setBarangayAddr(e.target.selectedOptions[0].text);
    }

    //button
    const buttonSave = async() => {
        console.log(user.currentUserData[0].UserUid);
        await updateDoc(doc(db, "Users", user.currentUserData[0].UserUid), {
            UserAddress: street +" "+ barangayAddr +" "+  cityAddr +" "+  provinceAddr +" "+  regionAddr,
        
          });
    }

    useEffect(() => {
        region();
        
    }, [])

  return (
    <Box>
      <Modal open={open}>
        <Box sx={style.boxModal}>
          <Box sx={style.modalContainer}>
            <Box sx={style.headerModal}>
              <SettingsIcon sx={style.modalIcon} />
              <Typography sx={style.modalHeadText}>Account Settings</Typography>
            </Box>

            <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>
                Street / Blk House Number{" "}
              </Typography>
              <TextField sx={style.textBoxModal}
              value={street}
              onChange={(e) => setStreet(e.target.value)}
              />
            </Box>

            <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>Region </Typography>
              <select style={{width:"100%",height:"40px",borderBlockColor:"gray",borderRadius:5}} onChange={province} onSelect={region}>
              {
                        regionData && regionData.length > 0 && regionData.map((item) => <option
                            key={item.region_code} value={item.region_code}>{item.region_name}</option>)
                    }
             
            </select>
            </Box>

            <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>Province </Typography>
              <select style={{width:"100%",height:"40px",borderBlockColor:"gray",borderRadius:5}} onChange={city} >
              
                {
                        provinceData && provinceData.length > 0 && provinceData.map((item) => <option
                            key={item.province_code} value={item.province_code}>{item.province_name}</option>)
                    }
                
               
              </select>
            </Box>

            <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>City </Typography>
              <select onChange={barangay} style={{width:"100%",height:"40px",borderBlockColor:"gray",borderRadius:5}}>
                    <option disabled>Select City</option>
                    {
                        cityData && cityData.length > 0 && cityData.map((item) => <option
                            key={item.city_code} value={item.city_code}>{item.city_name}</option>)
                    }
                </select><br/>
            </Box>

            <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>Barangay </Typography>
              <select onChange={brgy} style={{width:"100%",height:"40px",borderBlockColor:"gray",borderRadius:5}}>
                    <option disabled>Select Barangay</option>
                    {
                        barangayData && barangayData.length > 0 && barangayData.map((item) => <option
                            key={item.brgy_code} value={item.brgy_code}>{item.brgy_name}</option>)
                    }
                </select>
            </Box>
            <Typography variant="body2" color="initial" sx={{mb:2}}> {street},  {barangayAddr}, {cityAddr}, {provinceAddr}, {regionAddr}</Typography>
         

            {/* <Box sx={style.perItemModal}>
              <Typography sx={style.modalLabel}>Postal Code </Typography>
              <TextField sx={style.textBoxModal} type="number" />
            </Box> */}

            <Box sx={style.perItemModal}>
              <Button
                sx={style.logoutButton}
                onClick={() => window.location.reload()}
              >
                Cancel
              </Button>
            </Box>

            <Box sx={style.perItemModal}>
              <Button sx={style.saveButton} onClick={buttonSave}>Save</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
