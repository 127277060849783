// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

import deals from '../image/deals.jpg';


const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '75%',
        },
        margin: 'auto',
        marginTop: '20px',
    },

    titleContainer: {
        margin: '30px 0px 30px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    titleLabel: {
        fontFamily: 'bold',
        fontSize: '30px',
    },

    subTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '70%',
        margin: '0px auto 20px auto',
    },

    subTitleText: {
        fontFamily: 'poppins',
        fontSize: '16px',
        color: '#8F8E8D',
        textAlign: 'center',
    },

    mainTextContainer: {
        margin: '20px auto 20px auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
    },

    mainText1: {
        fontFamily: 'poppins',
        color: '#8F8E8D',
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '28px',
        marginBottom: '20px',
    },

    mainText2: {
        fontFamily: 'poppins',
        color: '#8F8E8D',
        textAlign: 'center',
        fontSize: '14px',
        lineHeight: '28px',
        marginBottom: '20px',
    },

    dealsBreadcrumbs: {
        height: '30vh',
        backgroundImage: `url(${deals})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '20px',
    },

    dealBigText: {
        fontFamily: 'bold',
        color: '#fff',
        fontSize: '30px',
        textTransform: 'uppercase',
    },

    dealSubText1Container: {
        marginTop: '10px'
    },

    dealSubText2Container: {
        marginBottom: '20px',
    },

    dealSubText1: {
        fontFamily: 'semibold',
        color: '#fff',
        textTransform: 'uppercase',
    },

    dealSubText2: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    goToShopButton: {
        backgroundColor: '#ad230e',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

}
export default style