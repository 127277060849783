import React from 'react'
import {Helmet} from "react-helmet";
// Import Styling
import style from '../styles/Developer';

// Import other Pages
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// Import Image
import bryce from '../image/Developers/resize/Bryce.jpg'
import vince from '../image/Developers/resize/Vince.jpg'
import luisa from '../image/Developers/resize/Luisa.jpg'
import meressel from '../image/Developers/resize/Meressel.jpg'

// Import Icon
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';

// Import Material UI
import {
    Box,
    Typography,
    Grid,

} from '@mui/material';

function Developer({isAuth}) {
    return (

            <Box sx={{ backgroundColor: '#f2f2f2', }}>

            <Helmet>
             <title>DC Monorack - Developers </title>
            <meta name="DC Monorack Developers " 
            content="We are a small group of on-the-job trainee developers from Bulacan State University who are enthusiastic about developing web applications, particularly ecommerce. We're here to assist you in developing your website using cutting-edge technology available today." />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>
                <Navbar isAuth={isAuth}/>

                {/*BreadCrumbs*/}
                <Box sx={style.breadcrumbsContainer}>
                    <Typography sx={style.breadcrumbsTitle}>Developers</Typography>
                    <Typography sx={style.breadcrumbsSubtitle}>The team behind this website</Typography>
                </Box>

                <Box sx={style.mainContainer}>

                    <Box sx={style.descTitleContainer}>
                        <Typography sx={style.descTitle}>
                            We live to learn, We live to code
                        </Typography>
                    </Box>

                    <Box sx={style.devDescriptionTextContainer}>
                        <Typography sx={style.devDescriptionText}>We are a small group of on-the-job trainee developers from Bulacan State University who are enthusiastic about developing web applications, particularly ecommerce. We're here to assist you in developing your website using cutting-edge technology available today.
                        </Typography>
                    </Box>

                    <Grid container sx={style.devMainContainer} spacing={2}>

                        <Grid item>
                            <Box sx={style.imgContainer}>
                                <img
                                    alt="aiisa"
                                    src={luisa}
                                    style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                            <Box sx={style.nameContainer}>
                                <Typography sx={style.devName}>Luisa De Jesus</Typography>
                            </Box>
                            <Box sx={style.positionContainer}>
                                <Typography sx={style.positionText}>Researcher</Typography>
                            </Box>
                            <Box sx={style.socmedMainContainer}>
                                <Box sx={style.socmedContainer}>
                                    <FacebookIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <LinkedInIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <InstagramIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <GoogleIcon sx={style.socmedIcon} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={style.imgContainer}>
                                <img
                                    alt="aiisa"
                                    src={vince}
                                    style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                            <Box sx={style.nameContainer}>
                                <Typography sx={style.devName}>Vince Daniel De Leon</Typography>
                            </Box>
                            <Box sx={style.positionContainer}>
                                <Typography sx={style.positionText}>Full Stack Developer</Typography>
                            </Box>
                            <Box sx={style.socmedMainContainer}>
                                <Box sx={style.socmedContainer}>
                                    <FacebookIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <LinkedInIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <InstagramIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <GoogleIcon sx={style.socmedIcon} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={style.imgContainer}>
                                <img
                                    alt="aiisa"
                                    src={bryce}
                                    style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                            <Box sx={style.nameContainer}>
                                <Typography sx={style.devName}>Bryce Angel Ganotice</Typography>
                            </Box>
                            <Box sx={style.positionContainer}>
                                <Typography sx={style.positionText}>Frontend Developer</Typography>
                            </Box>
                            <Box sx={style.socmedMainContainer}>
                                <Box sx={style.socmedContainer}>
                                    <FacebookIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <LinkedInIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <InstagramIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <GoogleIcon sx={style.socmedIcon} />
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box sx={style.imgContainer}>
                                <img
                                    alt="aiisa"
                                    src={meressel}
                                    style={{
                                        width: '100%',
                                        objectFit: 'contain',
                                        pointerEvents: "none"
                                    }}
                                />
                            </Box>
                            <Box sx={style.nameContainer}>
                                <Typography sx={style.devName}>Meressel Manongtong</Typography>
                            </Box>
                            <Box sx={style.positionContainer}>
                                <Typography sx={style.positionText}>System Tester</Typography>
                            </Box>
                            <Box sx={style.socmedMainContainer}>
                                <Box sx={style.socmedContainer}>
                                    <FacebookIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <LinkedInIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <InstagramIcon sx={style.socmedIcon} />
                                </Box>

                                <Box sx={style.socmedContainer}>
                                    <GoogleIcon sx={style.socmedIcon} />
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>

                </Box>

                <Footer />
            </Box>
     
    )
}

export default Developer