import React, { useState, useLayoutEffect, } from "react";
import { Link, useLocation } from "react-router-dom";

// Import Styling
import style from "../styles/Navbar";

// Import Icons
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import SupervisorAccountSharpIcon from "@mui/icons-material/SupervisorAccountSharp";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PlaceIcon from "@mui/icons-material/Place";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";

// Import Image
import logo from "../image/monorackLogo.png";
// import mem from "../image/Developers/resize/Meressel.jpg";
// import product from "../image/Product/Sample.jpg";

// Import Material UI
import {
  Box,
  Typography,
  Badge,
  Avatar,
  List,
  ListItem,
  Drawer,
  IconButton,
  Button,
  TextField,
  Divider,
  Grid,
} from "@mui/material";

// redux
import { useSelector } from "react-redux";

// firebase
import {signOut } from "firebase/auth";
import { auth } from "../utils/firebase";
import { useHistory } from "react-router-dom";
import {
  doc,
  updateDoc,
  serverTimestamp,
  increment,
  // deleteDoc,
} from "firebase/firestore";
import { db } from "../utils/firebase";

function Navbar({ isAuth }) {

  const [total, setTotal] = useState(0);
  const reducer = (previousValue, currentValue) => previousValue + currentValue;
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [search, setSearch] = useState("");

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let queryy = useQuery();
  let urlId = queryy.get("search");
  //  console.log(urlId);


  //button
  const buttonSearch = () => {
    history.push(`shop?search=${search}`);
  };

  // console.log("isAuth", isAuth);
  // Drawer - Menu Drawer
  const [menuDrawer, setMenuDrawer] = React.useState(false);

  // Drawer - Menu Cart
  const [cartDrawer, setCartDrawer] = React.useState(false);

  // Toggle Drawer - Menu Drawer
  const toggleDrawerMenu = (open) => (e) => {
    setMenuDrawer(open);
  };

  // Toggle Drawer - Cart Drawer
  const toggleDrawerCart = (open) => (e) => {
    setCartDrawer(open);
  };

  //Button
  const buttonLogout = () => {
    signOut(auth).then(() => {});
  };

  const incButton = (id) => {
      
  
    console.log("increment", id);
    const docRef = doc(db, "Carts", id);
    const payload = { CartQty: increment(1), UpdateCreated: serverTimestamp() };
    updateDoc(docRef, payload);


  };
  const decButton = (id) => {
      

    console.log("decrement", id);
    const docRef = doc(db, "Carts", id);
    const payload = {
      CartQty: increment(-1),
      UpdateCreated: serverTimestamp(),
    };
    updateDoc(docRef, payload);
   
  };

  //useEffect

    useLayoutEffect(() => {
   
    var rows = [0];
    let pro = 0;
    for (let i = 0; i < user.carts.length; i++) {
      pro =
        user.products.filter(
          (product, key) =>
            product.id === user.carts.map((cart) => cart.ProductId)[i]
        )[0].ProductPrice * user.carts.map((cart) => cart.CartQty)[i];
    //console.log(pro);
      rows.push(pro);
    }
    setTotal(rows.reduce(reducer));

    // console.log("row",rows.reduce(reducer))
  }, [total, user]);

  // Drawer Menu Content
  const menuDrawerContent = () => (
    <Box>
      {/*Header and Close Button*/}
      <Box sx={style.drawerHeader}>
        <Typography sx={style.bigTextHeader}>Menu</Typography>
        <IconButton onClick={toggleDrawerMenu(false)}>
          <CloseIcon sx={style.closeButton} />
        </IconButton>
      </Box>

      {/*Account Button*/}
      {/*
                Bali ganun ulit bit, kapag hindi pa naka login ang ilalagay mo eh ung icon na supervisor icon ... 
                pero pag naka login na , eh ung avatar na nung user.

                pag hindi pa nakalogin ang naka display na text ay "Hello, Sign-in", ung "My Account" as is na un wag mo nang alisin un;
            */}
      <Box sx={style.profileDrawerContainer}>
        <Box sx={style.avatarDrawerContainer}>
          <Avatar
            src={
              isAuth === true ? user.currentUserData[0].Image : "none"
            }
            sx={style.avatarPicture}
          />
          <SupervisorAccountSharpIcon sx={style.profileIconDrawer} />
        </Box>
        <IconButton onClick={()=> history.push("/profile")}>
          <Box sx={style.drawerInfoContainer}>
            <Typography sx={style.smallText} noWrap >
              Hello,{" "}
              {isAuth === true ? user.currentUserData[0].UserName : " Sign-in"}
            </Typography>
            <Typography sx={style.bigText}>My Account</Typography>
          </Box>
        </IconButton>
      </Box>

      {/*Links*/}
      <Box sx={style.drawerLinkContainer}>
        <Box sx={{ margin: "0px 0px 5px 0px" }} />

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkTitle}>Navigation</Typography>
          </Link>
        </Box>

        <Box sx={{ margin: "5px 0px 5px 0px" }} />

        <Box sx={style.perLink}>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Home</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/shop" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Shop</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/news" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>News {"&"} Blogs</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/about" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>About</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Contact</Typography>
          </Link>
        </Box>

        {isAuth === true ? (
          <Box sx={style.perLink} onClick={buttonLogout}>
            <Box sx={style.logoutContainer}>
              <Link style={{ textDecoration: "none" }}>
                <Typography sx={style.perLinkText}>Logout</Typography>
              </Link>

              <LogoutIcon sx={style.logoutIcon} />
            </Box>
          </Box>
        ) : (
          <Box sx={style.perLink}>
            <Box sx={style.logoutContainer}>
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Typography sx={style.perLinkText}>Sign In</Typography>
              </Link>
            </Box>
          </Box>
        )}

        <Box sx={{ margin: "5px 0px 5px 0px" }} />

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkTitle}>Others</Typography>
          </Link>
        </Box>

        <Box sx={{ margin: "5px 0px 5px 0px" }} />

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Refund {"&"} Return</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>
              Terms {"&"} Condition
            </Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>FAQ's</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>
              Shipping {"&"} Payment
            </Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Track your Orders!</Typography>
          </Link>
        </Box>

        <Box sx={style.perLink}>
          <Link to="/developer" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkText}>Developers</Typography>
          </Link>
        </Box>

        <Box sx={{ margin: "5px 0px 5px 0px" }} />

        <Box sx={style.perLink}>
          <Link to="/" style={{ textDecoration: "none" }}>
            <Typography sx={style.perLinkTitle}>Follow Us!</Typography>
          </Link>
        </Box>

        <Box sx={style.socmedIconDrawer}>
          <IconButton>
            <FacebookIcon sx={style.facebookDrawer} />
          </IconButton>

          <IconButton>
            <InstagramIcon sx={style.instagramDrawer} />
          </IconButton>

          <IconButton>
            <YouTubeIcon sx={style.youtubeDrawer} />
          </IconButton>
        </Box>

        <Box sx={style.perLink}>
          <Typography sx={style.perLinkText}>DC Monorack © 2022.</Typography>
        </Box>
      </Box>
    </Box>
  );

  // Drawer Cart Content
  const cartDrawerContent = () => (
    <Box>
      {/*Header and Close Button*/}
      <Box sx={style.drawerHeader}>
        <Typography sx={style.bigTextHeader}>Your Cart</Typography>
        <IconButton onClick={toggleDrawerCart(false)}>
          <CloseIcon sx={style.closeButton} />
        </IconButton>
      </Box>

      {/* Kapag wala pang nakalagay sa cart eto dapat ang naka display */}
      {user.carts.length === 0 ? (
        <Box>
        <Box sx={style.boxAlertGreenDrawer}>
          <Typography sx={style.warningText}>
            Your cart is currently empty.
          </Typography>
        </Box>
        <List>
        <Divider
          sx={{ backgroundColor: "#e6e6e6", width: "90%", margin: "auto" }}
        />
      </List>

      <Box sx={style.buttonContainer}>
            <Button variant="contained" sx={style.buttonCart} onClick={() => history.push("/shop")} >
             Shop Now
            </Button>
          </Box>
        </Box>
        
      ) : null}

     

      {/* Kapag may laman naman ganito dapat ang itsura */}
      <Box sx={style.productCartContainer}>
        {/* NOTE: isang Product, Isang Grid.
                    Naka package kasi yun, for every product, isang grid.
                */}
        {user.carts.map((cart) => (
          <Grid container spacing={1}>
            <Grid item>
              <img
                alt="product_picture"
                src={
                  user.products.filter(
                    (product) => product.id === cart.ProductId
                  )[0].Image[0]
                }
                style={{
                  width: "60px",
                  height: "60px",
                  objectFit: "contain",
                }}
              />
            </Grid>

            <Grid item sx={style.productInfoDrawerContainer} xs>
              <Box>
                {/*Product Name*/}
                <Typography sx={style.productNameCartDrawer}>
                  {
                    user.products.filter(
                      (product) => product.id === cart.ProductId
                    )[0].ProductName
                  }
                </Typography>

                {/*Product Description*/}
                <Box sx={style.descriptionBoxDrawerContainer}>
                  <Typography sx={style.productDescriptionCartDrawer}>
                    {user.products
                      .filter((product) => product.id === cart.ProductId)[0]
                      .ProductDesc.substring(0, 40)}
                    ...
                  </Typography>
                </Box>

                {/*Product Price*/}
                <Typography sx={style.productPriceCartDrawer}>
                  Php.{" "}
                  {
                    user.products.filter(
                      (product) => product.id === cart.ProductId
                    )[0].ProductPrice
                  }{" "}
                  .00
                </Typography>

                {/*Item */}
                <Box sx={style.productItemCountDrawerContainer}>
                  <Button
                    variant="outlined"
                    sx={style.addlessButton}
                    onClick={() => decButton(cart.id)}
                    disabled={cart.CartQty === 1 ? true : false}
                  >
                    -
                  </Button>
                  <TextField
                    sx={style.productQtyDrawerInput}
                    value={cart.CartQty}
                  />
                  <Button
                    variant="outlined"
                    sx={style.addlessButton}
                    onClick={() => incButton(cart.id)}
                  >
                    +
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>

      <List>
        <Divider
          sx={{ backgroundColor: "#e6e6e6", width: "90%", margin: "auto" }}
        />
      </List>

      {/* Total Price ng Product sa Cart Drawer */}
      {user.carts.length > 0 ? (
        <>
          <Box sx={style.cartDrawerPriceContainer}>
            <Typography sx={style.productTotalBig}>Total:</Typography>
            <Typography sx={style.productTotalSmall}>Php.{total}.00</Typography>
          </Box>

          <List>
            <Divider
              sx={{ backgroundColor: "#e6e6e6", width: "90%", margin: "auto" }}
            />
          </List>

          <Box sx={style.reminderContainer}>
            <Typography sx={style.reminderText}>
              Shipping and other fee will be calculated at checkout.
            </Typography>
          </Box>

          <List>
            <Divider
              sx={{ backgroundColor: "#e6e6e6", width: "90%", margin: "auto" }}
            />
          </List>

          <Box sx={style.buttonContainer}>
            <Button variant="contained" sx={style.buttonCart} onClick={()=> history.push("/cart")} >
              View Cart
            </Button>
            <Button variant="contained" sx={style.buttonCart}>
              Checkout
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );

  return (
    <Box>
      <Box sx={style.topMainContainer}>
        <Box sx={style.mainCenterContainer}>
          <Box sx={style.detailContainer}>
            <EmailIcon sx={style.topIcon} />
            <Typography sx={style.topText}>
              jomarsuriodelacruz@gmail.com
            </Typography>

            <CallIcon sx={style.topIcon} />
            <Typography sx={style.topText}>+(63) 935 374 4312</Typography>

            <PlaceIcon sx={style.topIcon} />
            <Typography sx={style.topText}>
              No.58, Purok 3, Bagong Silang, Plaridel, Bulacan
            </Typography>
          </Box>
        </Box>
      </Box>

      {/*Bottom Container - Start*/}
      <Box sx={style.bottomMainContainer}>
        <Box sx={style.centerBottomContainer}>
          {/*Logo Container - START*/}
          <Box sx={style.boxLogoContainer} onClick={() => history.push("/")}>
            <img
              src={logo}
              alt="company_logo"
              style={{
                maxWidth: "150px",
                height: "auto",
                pointerEvents: "none",
              }}
            />
          </Box>
          {/*Logo Container - END*/}

          {/*Eto ung cart kapag small screen - START*/}
          <Box sx={style.cartSmallScreenContainer}>
            <IconButton onClick={toggleDrawerCart(true)}>
              <Badge badgeContent={user.carts.length} color="error" sx={style.cartBadge}>
                <ShoppingCartIcon sx={style.cartIcon} />
              </Badge>
            </IconButton>
          </Box>
          {/*Eto ung cart kapag small screen - END*/}

          {/*SearchBox Container - START*/}
          <Box sx={style.searchBoxContainer}>
            <TextField
              sx={style.navSearchbar}
              placeholder="Search Product Here ..."
              defaultValue={urlId}
              
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button variant="contained" sx={style.searchButton} onClick={buttonSearch} >
              Search
            </Button>
          </Box>
          {/*SearchBox Container - END*/}

          {/*User Info Container - START*/}
          <Box sx={style.userInfoMainContainer}>
            <Box sx={style.userInfoSubContainer}>
              <IconButton>
                {/*
                                    Yung naka display na Icon eh yan ung itsura kapag hindi
                                    pa nakakapag login.

                                    Palitan mo nalang ng Avatar ng Mismong nag login, if gusto mo.

                                    i-didisplay none ko nalang sya sa styling.

                                    Tapos dun sa may "Hello, Sign in" mapapalitan yun, once na
                                    na i login na ung account, mapapalitan ng pangalan ng user,
                                    magiging "Hello, Meressel Manongtong", Naka ellipsis naman na to kaya hindi na hahaba.

                                    Wrap up:
                                    1. Pag di pa naka sign-in, ang naka display ay:
                                        Account Icon lang,
                                        At ung text ay "Hello, Sign in" lang.
                                    
                                    2. Pag naka sign-in na, ang naka display naman ay:
                                        Avatar ng user,
                                        At yung text naman ay "Hello, Pangalan ng User".
                                */}

                <SupervisorAccountSharpIcon sx={style.accountIcon} />
                <Avatar
                  src={
                    isAuth === true
                      ? user.currentUserData[0].Image
                      : "none"
                  }
                  sx={style.profileAvatar}
                />
                <Box sx={style.userAccountContainer} onClick={()=> history.push("/profile")}>
                  <Typography sx={style.smallText} noWrap>
                    Hello,{" "}
                    {isAuth === true
                      ? user.currentUserData[0].UserName 
                      : " Sign-in"}
                  </Typography>
                  <Typography sx={style.bigText}>My Account</Typography>
                </Box>
              </IconButton>
            </Box>

            <Box sx={style.userInfoSubContainer}>
              <IconButton
                onClick={
                  isAuth === true
                    ? toggleDrawerCart(true)
                    : () => history.push("/login")
                }
              >
                <Badge
                  badgeContent={user.carts.length}
                  color="error"
                  sx={style.cartBadge}
                >
                  <ShoppingCartIcon sx={style.cartIcon} />
                </Badge>
                <Box sx={style.userAccountContainer}>
                  <Typography sx={style.smallText}>My Cart</Typography>
                  <Typography sx={style.bigText}>
                    ₱ {isAuth === true ? total : "0"}.00
                  </Typography>
                </Box>
              </IconButton>
            </Box>
          </Box>
          {/*User Info Container - END*/}
        </Box>
      </Box>
      {/*Bottom Container - END*/}

      {/*Navigation Container - START*/}
      <Box sx={style.navigationContainer}>
        <Box sx={style.centerNavigationContainer}>
          <Box sx={style.linkListContainer}>
            <List sx={style.linkList}>
              <ListItem>
                <Link to="/home" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>Home</Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/shop" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>Shop</Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/news" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>News {"&"} Blogs</Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>About</Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>Contact</Typography>
                </Link>
              </ListItem>

              <ListItem>
                <Link to="/profile" style={{ textDecoration: "none" }}>
                  <Typography sx={style.navLink}>Track your order</Typography>
                </Link>
              </ListItem>
            </List>
          </Box>

          <Box sx={style.detailContainer}>
            {/*
                            paki link mo nalang ung mga social media icons sa mismong socmed ni boss jomar, bigay ko nalang sayo link ng tatlo na yan.
                        */}

            <Typography sx={style.navText}>Follow Us:</Typography>
            <FacebookIcon sx={style.facebookIcon} />
            <InstagramIcon sx={style.instagramIcon} />
            <YouTubeIcon sx={style.youtubeIcon} />
          </Box>
        </Box>
      </Box>
      {/*Navigation Container - END*/}

      <Box sx={style.searchContainerSmallScreen}>
        <Box sx={style.menuIconContainer}>
          <IconButton onClick={toggleDrawerMenu(true)}>
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
        </Box>

        <Box sx={style.searchSmallContainer}>
          <TextField
            sx={style.navSearchbarSmall}
            placeholder="Search Product Here ..."
            defaultValue={urlId}
          
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button variant="contained" sx={style.searchButtonSmall} onClick={buttonSearch}>
            Search
          </Button>
        </Box>
      </Box>

      {/*All Drawers Here - START*/}

      {/*Drawer Menu*/}
      <Drawer
        anchor={"left"}
        open={menuDrawer}
        
        PaperProps={{
          sx: {
            boxShadow: 0,
            background: "#fff",
            width: 300,
          },
        }}
        sx={style.drawerMenu}
      >
        {menuDrawerContent()}
      </Drawer>

      {/* Drawer Cart */}
      <Drawer
        anchor={"right"}
        open={cartDrawer}

        PaperProps={{
          sx: {
            boxShadow: 0,
            background: "#fff",
            width: 300,
          },
        }}
      >
        {cartDrawerContent()}
      </Drawer>

      {/*All Drawers Here - END*/}
      </Box>
  );
}

export default Navbar;
