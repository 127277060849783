import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '80%',
            lg: '70%',
        },
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#fff',
    },

    mainLeftGrid: {
    },

    mainRightGrid: {
    },

    gridLeftContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '10px',
    },

    gridLeftTitle: {
        fontFamily: 'semibold',
        marginBottom: '30px',
        fontSize: '14px',
    },

    perCardContainer: {
        display: 'flex',
    },

    cardPost: {
        borderRadius: 0,
        boxShadow: 0,
        display: 'flex',
        backgroundColor: '#fff',
        height: '80px',
        marginBottom: '10px',
    },

    postTitle: {
        fontFamily: 'semibold',
        color: '#000',
        fontSize: '14px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },

    postContent: {
        fontFamily: 'poppins',
        color: '#8F8E8D',
        fontSize: '13px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },

    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 2,
    },

    postDateContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    calendarIcon: {
        color: '#ad230e',
        fontSize: '20px',
        marginRight: '10px',
    },

    postDate: {
        fontFamily: 'poppins',
        color: '#8F8E8D',
        fontSize: '13px',
    },

    articlePictureContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    articleContentContainer: {
        marginTop: '20px',
        borderLeft: '4px solid #ad230e',
        paddingLeft: '20px'
    },

    articleTitleContainer: {
        padding: '10px',
        marginTop: '10px',
    },

    articleTitle: {
        fontFamily: 'bold',
        fontSize: '20px',
    },
    
    articleContent: {
        fontFamily: 'poppins',
        fontSize: '14px',
        lineHeight: '25px',
        color: '#6b6a69',
    },
}
export default style