import * as actionTypes from "../types";

const initialState ={
    displayName:"",
    currentUserData: [""],
    products: [],
    motors: [],
    carts:[],
    newsBlogs: [],
    orders: [],
    error: null, 
}

 function userReducer(state=initialState, action)
{
    switch(action.type)
    {

        case actionTypes.SET_MY_DATA: 
        return{
            ...state,
            currentUserData: action.payload,
        };

        case actionTypes.SET_PRODUCT: 
        return{
            ...state,
            products: action.payload,
        };
        
        case actionTypes.SET_MOTOR: 
        return{
            ...state,
            motors: action.payload,
        };

        case actionTypes.SET_CART: 
        return{
            ...state,
            carts: action.payload,
        };
        case actionTypes.SET_NEWS_BLOGS: 
        return{
            ...state,
            newsBlogs: action.payload,
        };
        case actionTypes.SET_ORDERS: 
        return{
            ...state,
            orders: action.payload,
        };

        case actionTypes.USER_ERROR: 
        return{
            ...state,
            error: action.payload,
        };
        default:
            return{
                ...state,
            }
    }
}

export default userReducer;