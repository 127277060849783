import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
// Import Style
import style from '../styles/NewShop'

// Import other Pages
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// Import Image
// import product from '../image/Product/Sample.jpg'

// Import Material UI
import {
    Box,
    Typography,
    Grid,
    // IconButton,
    Button,
    // Divider,
    // TextField,
    Select,
    MenuItem,
    // OutlinedInput,
    // Menu,
    Card,
    CardActionArea,
    CardMedia,
    CardContent,
} from '@mui/material';

//redux
import { useSelector } from 'react-redux';
import {  useLocation, useHistory } from "react-router-dom";

function NewShop({ isAuth }) {
    const history = useHistory();
    const user = useSelector(state => state.user);
    const [brand, setBrand] = React.useState(0);
    const [model, setModel] = React.useState(0);
    const [version, setVersion] = React.useState(0);
    const [filterProducts, setFilterProducts] = useState([]);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
      };
      let queryy = useQuery();
      let urlId = queryy.get("search");
 

       
    // const [defaultBrand, setDefaultBrand] = React.useState('Select Motorcycle Brand');

    const handleBrand = (event) => {
        setBrand(event.target.value);
    };

    const handleModel = (event) => {
        setModel(event.target.value);
    };

    const handleVersion = (event) => {
        setVersion(event.target.value);
    };


    //button
    const buttonViewProduct = (id) => {
        history.push(`productdetail?product=${id}`);
        // dispatch(findProduct(id));
      };

      useEffect(() => {
        
          
  
        if ( brand === "Honda") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Honda")
              );
              if ( model === 0 ){
                setVersion(0);       
            
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              } else if (version !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                );
                
              }
             } else if ( brand === "Yamaha") {
                setFilterProducts(
                    user.products.filter((product) => product.ProductCategory === "Yamaha")
                  );
                  if ( model === 0 ){
                    setVersion(0);
                
                
                  
                  } else if (model !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductMotorCategory === model)
                    );
                    if (version !== 0)  {
                        setFilterProducts(
                          user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                        );
                        
                      }
                
                  }
         } else if ( brand === "Suzuki") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Suzuki")
              );
              if ( model === 0 ){
                setVersion(0);
            
            
              
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              }
         } else if ( brand === "Kawasaki") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Kawasaki")
              );
              if ( model === 0 ){
                setVersion(0);
            
            
              
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              }
          } else if ( brand === "Rusi") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Rusi")
              );
              if ( model === 0 ){
                setVersion(0);
            
            
              
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              }
          } else if ( brand === "Sym") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Sym")
              );
              if ( model === 0 ){
                setVersion(0);
            
            
              
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              }
          } else if ( brand === "Euro Motor") {
            setFilterProducts(
                user.products.filter((product) => product.ProductCategory === "Euro Motor")
              );
              if ( model === 0 ){
                setVersion(0);
            
            
              
              } else if (model !== 0)  {
                setFilterProducts(
                  user.products.filter((product) => product.ProductMotorCategory === model)
                );
                if (version !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                    );
                    
                  }
            
              }
            } else if ( brand === "Kymco") {
                setFilterProducts(
                    user.products.filter((product) => product.ProductCategory === "Kymco")
                  );
                  if ( model === 0 ){
                    setVersion(0);
                
                
                  
                  } else if (model !== 0)  {
                    setFilterProducts(
                      user.products.filter((product) => product.ProductMotorCategory === model)
                    );
                    if (version !== 0)  {
                        setFilterProducts(
                          user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                        );
                        
                      }
                
                  }
                } else if ( brand === "Keeway") {
                    setFilterProducts(
                        user.products.filter((product) => product.ProductCategory === "Keeway")
                      );
                      if ( model === 0 ){
                        setVersion(0);
                    
                    
                      
                      } else if (model !== 0)  {
                        setFilterProducts(
                          user.products.filter((product) => product.ProductMotorCategory === model)
                        );
                        if (version !== 0)  {
                            setFilterProducts(
                              user.products.filter((product) => product.ProductVersion === version &&  product.ProductMotorCategory === model) 
                            );
                            
                          }
                    
                      }
                 } else if(urlId !== null ){
                setFilterProducts(
                user.products.filter((product) =>
                  product.ProductName.toLowerCase().includes(
                    urlId.toLowerCase()
                  )
                )
              );

        } else {
            setModel(0);
            setVersion(0);
            setFilterProducts(user.products);
           
        
        }
     

      }, [user, urlId, model,brand, version])
      
    
    return (

            <Box sx={{ backgroundColor: '#f2f2f2', }}>
            <Helmet>
             <title>DC Monorack - Shop </title>
            <meta name="DC Monorack Shop " 
            content="SELECT YOUR BRACKET HERE, Motorcycle Brand, Motorcycle Model, Monorack Version Version " />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

                <Navbar isAuth={isAuth}/>

                {/*BreadCrumbs*/}
                <Box sx={style.breadcrumbsContainer} />

                {/* Search Container */}
                <Box sx={style.searchContainer}>

                    <Box sx={style.imageMotorContainer} />


                    <Box sx={style.headTextTitle}>
                        <Typography sx={style.filterHeadTitle}>Select Your Bracket Here</Typography>
                    </Box>

                    <Grid container sx={style.filterItemContainer}>

                        <Grid item sx={style.filterPerItem} xs={12} sm={12} md={3.65} lg={3.2}>
                            <Typography sx={style.filterLabel}>Motorcycle Brand</Typography>
                            <Select
                                onChange={handleBrand}
                                value={brand}
                                variant="outlined"
                                sx={style.selectBrand}
                                // disabled={filterProducts.length === 0 ? true : false}
                            >
                                   <MenuItem value={0} sx={style.itemText}>All Brand</MenuItem>
                                <MenuItem value={"Honda"} sx={style.itemText}>Honda</MenuItem>
                                <MenuItem value={"Suzuki"} sx={style.itemText}>Suzuki</MenuItem>
                                <MenuItem value={"Kawasaki"} sx={style.itemText}>Kawasaki</MenuItem>
                                <MenuItem value={"Rusi"} sx={style.itemText}>Rusi</MenuItem>
                                <MenuItem value={"Sym"} sx={style.itemText}>Sym</MenuItem>
                                <MenuItem value={"Euro Motor"} sx={style.itemText}>Euro Motor</MenuItem>
                                <MenuItem value={"Yamaha"} sx={style.itemText}>Yamaha</MenuItem>
                                <MenuItem value={"Kymco"} sx={style.itemText}>Kymco</MenuItem>
                                <MenuItem value={"Keeway"} sx={style.itemText}>Keeway</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item sx={style.filterPerItem} xs={12} sm={12} md={3.65} lg={3.2}>
                            <Typography sx={style.filterLabel}>Motorcycle Model</Typography>
                            <Select
                                disabled={brand === 0 || user.motors.filter(function (a) {
                                    return a.MotorCategory === brand }).length === 0 ? true : false}
                                onChange={handleModel}
                                value={model}
                                variant="outlined"
                                sx={style.selectBrand}
                            >
                              <MenuItem value={0}>All Model</MenuItem> 

                          { user.motors.filter(function (a) {
                                return a.MotorCategory === brand })
                                .map((motor) => (
                               
                                    <MenuItem value={motor.MotorName}>{motor.MotorName}</MenuItem> 
                                ))}
          
         
                            </Select>
                        </Grid>

                        <Grid item sx={style.filterPerItem} xs={12} sm={12} md={3.65} lg={3.2}>
                            <Typography sx={style.filterLabel}>Monorack Version Version</Typography>
                            <Select
                                disabled={model === 0 || user.motors.filter(function (a) {
                                    return a.MotorCategory === brand }).length === 0 ? true : false}
                                onChange={handleVersion}
                                value={version}
                                variant="outlined"
                                sx={style.selectBrand}
                            >
                                 <MenuItem value={0} sx={style.itemText}>All Version</MenuItem>
                                <MenuItem value={1} sx={style.itemText}>Version 1</MenuItem>
                                <MenuItem value={2} sx={style.itemText}>Version 2</MenuItem>
                                <MenuItem value={3} sx={style.itemText}>Version 3</MenuItem>
                            </Select>
                        </Grid>

                        <Grid item sx={style.filterButton} xs={12} sm={12} md={5} lg={1.3}>
                            <Box sx={style.buttonContainer}>
                                <Button variant="contained" sx={style.searchButton}>Go</Button>
                            </Box>
                        </Grid>

                    </Grid>
                </Box>

                {/* Main Store */}
                <Box sx={style.mainContainer}>

                    <Grid container sx={style.productMainContainer} spacing={2}>

                        {/* Product */}
                        {filterProducts.map((product,i) => (
                        <Grid item sx={style.perItem}  key={i} >
                            <Card sx={style.cardProduct}>
                                <CardActionArea  onClick={() => buttonViewProduct(product.id)}>
                                    <CardMedia
                                        component="img"
                                        height="180"
                                        src={product.Image[0]}
                                        alt="product"                  
                                        style={{ pointerEvents: "none"}}
                                       
                                    />

                                    <CardContent sx={{ padding: '8px' }} onClick={() => buttonViewProduct(product.id)} >
                                        <Box sx={style.boxTitleContainer}>
                                            <Typography sx={style.productTitle}>{product.ProductName}</Typography>
                                        </Box>

                                        <Box sx={style.boxDescriptionContainer}>
                                            <Typography sx={style.productDescription}>
                                               {product.ProductDesc}
                                            </Typography>
                                        </Box>

                                        <Box>
                                            <Typography sx={style.productPrice}>
                                                ₱ {product.ProductPrice}.00
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        ))}
                        {/* Product */}
                       

                    

                    </Grid>
                    
                    <Box sx={style.headTextTitle}>
                    {filterProducts.length === 0 ? ( <Typography sx={style.filterHeadTitle}>{urlId + " no found"}</Typography> ) : null }
                    </Box>
                   
                </Box>


             




                <Footer />
            </Box>
 
    )
}

export default NewShop