import React, { useState } from "react";
// import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";
// Import Styling
import style from "../styles/ForgotPassword";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GoogleIcon from "@mui/icons-material/Google";

// import { useHistory } from "react-router-dom";

// firebase
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// Import Material UI
import { Box, Typography, Button, TextField, Grid } from "@mui/material";

function ForgotPassword() {
  // const history = useHistory();
  const [email, setEmail] = useState("");
  const [noEmail, setNoEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const buttonSend = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
        setEmail("");
        setEmailSent(true);
        setNoEmail(false);
        setInvalidEmail(false);
      })
      .catch((error) => {
        //const errorCode = error.code;
        const errorMessage = error.code;
        // alert(errorMessage);

        if (errorMessage === "auth/missing-email") {
          setNoEmail(true);
          setInvalidEmail(false);
          setEmailSent(false);
        } else if (errorMessage === "auth/invalid-email") {
          setInvalidEmail(true);
          setEmailSent(false);
          setNoEmail(false);
        }
      });
  };

  return (
    <Box>
       <Helmet>
             <title>DC Monorack - Forgot Password </title>
            <meta name="DC Monorack Forgot Password " 
            content="Forgot Password" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

      <Navbar />

      {/*BreadCrumbs*/}
      <Box sx={style.breadcrumbsContainer}>
        <Typography sx={style.breadcrumbsTitle}>Forgot Password</Typography>
        <Typography sx={style.breadcrumbsSubtitle}>
          Home / Login / Forgot Password
        </Typography>
      </Box>

      {/*Login Form*/}
      <Box sx={style.mainLoginContainer}>
        <Box sx={style.mainContainer}>
          {noEmail && (
            <Box sx={style.warningBoxYellow}>
              <Typography sx={style.warningText}>
                Kindly input your Username/Email to recover your account. We
                will send you an email to confirm and update your account. Thank
                you very much.
              </Typography>
            </Box>
          )}

          {emailSent && (
            <Box sx={style.warningBoxGreen}>
              <Typography sx={style.warningText}>
                Confirmation email sent successfully. Please check your inbox to
                reset your account.
              </Typography>
            </Box>
          )}

          {invalidEmail && (
            <Box sx={style.warningBoxRed}>
              <Typography sx={style.warningText}>
                There's no such Username/Email registered. Please check and try
                again!
              </Typography>
            </Box>
          )}

          <Typography sx={style.credentialsLabel}>
            Username / Email Address
          </Typography>

          <Grid container sx={style.gridMain}>
            <Grid item xs={12} sm={12} md={10} lg={10} sx={style.gridTextbox}>
              <TextField
                sx={style.inputBox}
                value={email}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sx={style.gridButton}>
              <Button
                variant="contained"
                sx={style.recoverButton}
                onClick={buttonSend}
              >
                Recover
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}

export default ForgotPassword;
