// import breadcrumbs from '../image/breadcrumbs.jpg';

import motors from '../image/motor.png'



const style = {
    breadcrumbsContainer: {
        height: {
            xs: '25vh',
            sm: '28vh',
            md: '28vh',
        },
        // backgroundImage: `url(${breadcrumbs})`,
        backgroundColor: '#ad230e',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        width: '100%',
    },

    imageMotorContainer: {
        backgroundImage: `url(${motors})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '100%',
        height: {
            xs: '250px',
            sm: '300px',
            md: '300px',
            lg: '300px',
        },
        marginTop: {
            xs: '-150px',
            sm: '-150px',
            md: '-150px',
            lg: '-150px',
        },
    },

    headTextTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: {
            xs: '-50px',
            sm: '-10px',
            md: '-10px',
            lg: '-20px',
        },
    },

    searchContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '70%',
            lg: '70%',
        },
        backgroundColor: '#e6e1e1',
        margin: '-80px auto 20px auto',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '70%',
            lg: '70%',
        },

        margin: '20px auto 20px auto',
        padding: '10px',
    },


    filterHeadTitle: {
        fontFamily: 'semibold',
        letterSpacing: '2px',
        fontSize: '16px',
        color: '#1A1A1A',
        textTransform: 'uppercase',
        marginBottom: '20px',
        textAlign: 'center',
    },

    filterPerItem: {
        width: '100%',
        margin: '5px',
    },

    filterButton: {
        width: '100%',
        margin: '10px',
    },

    filterLabel: {
        fontFamily: 'poppins',
        fontSize: '13px',
        marginBottom: '3px',
        color: '#525252',
    },

    itemText: {
        fontFamily: 'poppins',
        fontSize: '13px',

        '&:focus': {
            backgroundColor: 'transparent'
        },

    },

    selectBrand: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 0,


        "& .MuiSvgIcon-root": {
            color: "#4A4A4A",
        },

        '& .MuiInputBase-input': {
            fontFamily: 'poppins',
            color: '#4A4A4A',
            padding: '10px',
            fontSize: '13px',
        },
    },

    filterItemContainer: {
        alignItems: 'center',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-between',
        },
    },

    buttonContainer: {
        display: 'flex',
        alignItems: 'bottom',
        justifyContent: 'bottom',
    },

    searchButton: {
        width: '100%',
        backgroundColor: '#ad230e',
        borderRadius: 0,
        boxShadow: 0,
        fontFamily: 'poppins',

        marginTop: {
            xs: 0,
            sm: 0,
            md: 0,
            lg: '22px',
        },

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    cardProduct: {
        maxWidth: {
            xs: 156,
            lg: 180,
        },
        borderRadius: 0,
        backgroundColor: '#f2f2f2',
        padding: '3px',
    },

    perItem: {
        margin: {
            xs: '0px 0px 5px 0px',
            sm: '0px 0px 5px 0px',
            md: '0px 8px 8px 5px',
            lg: '0px 8px 8px 5px',
        },
    },

    productTitle: {
        fontFamily: 'semibold',
        fontSize: '14px',
        marginBottom: '5px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        width: '170px',
    },

    productDescription: {
        fontFamily: 'poppins',
        fontSize: '12px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        width: '170px',
    },

    boxDescriptionContainer: {
        height: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '2px',
    },

    productPrice: {
        fontFamily: 'poppins',
        color: '#cf2d00',
        fontSize: '13px',
    },

    productMainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: {
            xs: 'space-evenly',
            sm: 'space-evenly',
            md: 'center',
            lg: 'center',
        },
        marginBottom: '50px',
    },
}
export default style