import React, { useState, useEffect, } from "react";
// import { Helmet } from "react-helmet";

import { ThemeProvider, createTheme } from "@mui/material";
import theme from "../utils/theme";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
// import Shop from "../pages/Shop";
// import MotorShop from "../pages/MotorShop";
// import Monorack from "../pages/Monorack";
import ProductDetail from "../pages/ProductDetail";
import Cart from "../pages/Cart";
import About from "../pages/About";
import ForgotPassword from "../pages/ForgotPassword";
import NewShop from "../pages/NewShop";
import Contact from "../pages/Contact";
import Developer from "../pages/Developer";
import News from "../pages/News";
import NewsPost from "../pages/NewsPost";
import Loading from "../components/Loading";
import Profile from "../pages/Profile";
import Checkout from "../pages/Checkout";
import NotFound from "../pages/404";

//redux
import {
  setMyData,
  setProduct,
  setMotors,
  setCarts,
  setNewsBlogs,
  setOrders,
} from "../redux/actions/userAction";
import { useSelector, useDispatch } from "react-redux";

//backend
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../utils/firebase";
import { getTheme, getLang } from "../redux/actions/uiAction";
import {
  where,
  collection,
  query,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

export default function Routes() {
  const dispatch = useDispatch();
  const ui = useSelector((state) => state.ui);
  const THEME = createTheme(theme(ui.isDarkMode));
  const [state, setstate] = useState({
    isAuth: false,
    isLoading: true,
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // console.log(user.uid);

      //product
      const collectionRefProducts = collection(db, "Products");
      const products = query(collectionRefProducts, orderBy("Created"));
      onSnapshot(products, (snapshot) =>
        dispatch(
          setProduct(
            snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          )
        )
      );
      //motors
      const collectionRefMotors = collection(db, "Motors");
      const qMotors = query(collectionRefMotors, orderBy("Created"));
      onSnapshot(qMotors, (snapshot) =>
        dispatch(
          setMotors(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        )
      );

        //news and blogs
        const collectionRefNewsBlogs = collection(db, "NewsBlogs");
        const qNewsBlogs= query(collectionRefNewsBlogs, orderBy("Created"));
        onSnapshot(qNewsBlogs, (snapshot) =>
          dispatch(
            setNewsBlogs(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

      //cart
      dispatch(setCarts([]));

      if (user && user.emailVerified) {
        // ...
        // console.log(auth);

        //myuser
        const collectionRefMyData = collection(db, "Users");
        const MyData = query(
          collectionRefMyData,
          where("UserUid", "==", user.uid)
        );
        onSnapshot(MyData, (snapshot) =>
          dispatch(
            setMyData(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );

        //cart
        const collectionRefCarts = collection(db, "Carts");
        const qCarts = query(
          collectionRefCarts,
          where("BuyerUid", "==", user.uid)
        );
        onSnapshot(qCarts, (snapshot) =>
          dispatch(
            setCarts(
              snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
            )
          )
        );


         //Orders
         const collectionRefOrders = collection(db, "Orders");
         const qOrders = query(collectionRefOrders , where("BuyerUid", '==', user.uid));
         onSnapshot(qOrders, (snapshot) =>
           dispatch(
             setOrders(
               snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
             )
           )
         );

        setstate({ isAuth: true, isLoading: false });
      } else {
        setstate({ isAuth: false, isLoading: false });
      }
    });

    dispatch(getLang(), getTheme());
  }, [dispatch]);

  if (state.isLoading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={THEME}>

      <Router>

      


        <Switch>
          <Route path="/" exact>
            <Redirect to="/home" />
          </Route>

          <PublicRoute
            restricted={true}
            component={Login}
            isAuth={state.isAuth}
            path="/login"
            exact
          />

          <PublicRoute
            restricted={true}
            component={Register}
            isAuth={state.isAuth}
            path="/register"
            exact
          />
          <PrivateRoute
            component={Cart}
            isAuth={state.isAuth}
            path="/cart"
            exact
          />
          <PublicRoute
            component={Home}
            isAuth={state.isAuth}
            path="/home"
            exact
          />

          <PublicRoute
            component={ProductDetail}
            isAuth={state.isAuth}
            path="/productdetail"
            exact
          />
          <PublicRoute
            component={NewShop}
            isAuth={state.isAuth}
            path="/shop"
            exact
          />
          <PublicRoute
            restricted={true}
            component={ForgotPassword}
            isAuth={state.isAuth}
            path="/forgotpassword"
            exact
          />
          <PublicRoute
            component={Contact}
            isAuth={state.isAuth}
            path="/contact"
            exact
          />
          <PublicRoute
            component={Developer}
            isAuth={state.isAuth}
            path="/developer"
            exact
          />
          <PublicRoute
            component={About}
            isAuth={state.isAuth}
            path="/about"
            exact
          />
            <PublicRoute
            component={News}
            isAuth={state.isAuth}
            path="/news"
            exact
          />
          <PublicRoute
            component={NewsPost}
            isAuth={state.isAuth}
            path="/newspost"
            exact
          />
            <PrivateRoute
            component={Profile}
            isAuth={state.isAuth}
            path="/profile"
            exact
          />
           <PrivateRoute
            component={Checkout}
            isAuth={state.isAuth}
            path="/checkout"
            exact
          />
         

          <Route component={NotFound} />
          
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
