// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
    },

    mainRegistrationContainer: {
        width: '90%',
        margin: 'auto',
        marginBottom: '20px',
    },

    warningFailedBox: {
        backgroundColor: '#ffd1d1',
        height: '40px',
        border: '1px solid #d91c1c',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        padding: '5px',
    },

    warningSuccessBox: {
        backgroundColor: '#eeffeb',
        border: '1px solid #30db12',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        padding: '5px',
    },

    warningText: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    registrationFormBox: {
        width: {
            xs: '80%',
            sm: '70%',
            md: '60%',
            lg: '40%',
        },
        backgroundColor: '#f2f2f2',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 30px 10px 30px',
        marginTop: '15px',
    },

    registerTitle: {
        fontFamily: 'bold',
        fontSize: '20px',
    },

    directionText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        margin: '5px 0px 20px 0px',
    },

    credentialsLabel: {
        fontFamily: 'Poppins',
        fontSize: '14px',
    },

    inputBox: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #479923',
            },

            fontFamily: 'Poppins',
            fontSize: '13px',
            borderRadius: 0,
            backgroundColor: '#fff',
            marginBottom: '10px'
        },

        '& .MuiInputBase-input': {
            color: '#000',
        },
    },

    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    containerButton: {
        width: '45%',
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px'
    },

    containerLink: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px'
    },

    createButton: {
        borderRadius: 0,
        backgroundColor: '#479923',
        width: '80%',
        fontFamily: 'Poppins',
        textTransform: 'capitalize',
        boxShadow: 0,
    },

    returnToShop: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#e35b00',
    },
}
export default style;