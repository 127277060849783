// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

// const LINES_TO_SHOW = 2;

const style = {

    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '80%',
        },
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
        backgroundColor: '#fff',
    },

    nothingContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '80%',
        },
        margin: 'auto',
        marginTop: '20px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        padding: '20px 0px 20px 0px',
        flexDirection: 'column',
    },

    iconContainer: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },

    shoppingIconNone: {
        fontSize: '80px',
    },

    textContainer: {
        display: 'flex',
        alignItem: 'center',
        textAlign: 'center',
        flexDirection: 'column',
        marginTop: '10px',
    },

    boldText: {
        fontFamily: 'semibold',
        fontSize: '20px',
    },

    subText: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    buttonContainer: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        marginTop: '20px',
    },

    shopButton: {
        backgroundColor: '#ad230e',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    cartMainContainer: {
        padding: '10px',
    },

    titleTextCart: {
        fontFamily: 'Semibold',
    },

    productContainer: {
        marginTop: '10px',
        width: {
            xs: '99%',
            sm: '99%',
            md: '97%',
            lg: '97%',
        },
        border: '1px solid #e6e6e6',
    },

    removeButtonContainer: {
        display: 'flex',
        alignItem: 'center',
    },

    removeButton: {
        backgroundColor: '#ad230e',
        minWidth: '20px',
        height: '20px',
        boxShadow: 0,
        borderRadius: 0,
        padding: 0,

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    closeIcon: {
        fontSize: '15px',
    },

    productPictureGrid: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },

    descGridContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '92%',
        marginLeft: {
            xs: '10px',
        },
    },

    boxProd: {

    },

    prodText: {
        fontFamily: 'semibold',
        fontSize: '17px',
        color: '#ad230e',
    },

    boxDesc: {
        width: '97%',
    },

    descText: {
        fontFamily: 'poppins',
        fontSize: '14px',
        color: '#212121',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },

    boxPrice: {
        display: 'flex',
        alignItems: 'center',
    },

    priceLabel: {
        fontFamily: 'semibold',
        marginRight: '10px',
        fontSize: '14px',
    },

    priceText: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    boxQty: {
        display: 'flex',
        alignItems: 'center',
    },

    productItemCountDrawerContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    addlessButton: {
        borderRadius: 0,
        fontFamily: 'semibold',
        color: '#ad230e',
        borderColor: '#e6e6e6',
        padding: 0,
        minWidth: '30px',

        '&:hover': {
            transition: 'all ease .4s',
            color: '#000',
            border: '1px solid #ad230e',
            backgroundColor: 'transparent',
        },
    },

    productQtyDrawerInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #e6e6e6',
            },

            fontFamily: 'Poppins',
            fontSize: '12px',
            padding: 0,
            width: '40px',
            height: '26.5px',
            borderRadius: 0,
        },

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
            textAlign: 'center',
        },

    },

    cartControlContainer: {
        width: {
            xs: '99%',
            sm: '99%',
            md: '97%',
            lg: '97%',
        },
        marginBottom: {
            xs: '10px',
            sm: '10px',
            md: 0,
            lg: 0,
        },
        marginTop: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    continueShopping: {
        backgroundColor: '#ad230e',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    refreshButton: {
        backgroundColor: '#ad230e',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    secondGridContainer: {
        border: '1px solid #e6e6e6',
        marginTop: '10px',
    },

    summaryBox: {
        padding: '10px',
    },

    priceSummaryContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    summaryLabel: {
        fontFamily: 'semibold',
    },

    summaryPrice: {
        fontFamily: 'poppins',
    },

    messageBoxContainer: {
        padding: '10px',
    },

    messageLabel: {
        fontFamily: 'poppins',
        fontSize: '14px',
        display: 'flex',
        flexDirection: 'column',
    },

    messageBox: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #ad230e',
            },

            fontFamily: 'Poppins',
            fontSize: '14px',
            padding: '5px',
            borderRadius: 0,
        },

        width: '100%',
        marginTop: '5px',

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
        },
    },

    checkoutContainer: {
        padding: '0px 10px 10px 10px',
    },

    checkoutButton: {
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        backgroundColor: '#ad230e',
        width: '100%',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

}
export default style