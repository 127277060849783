import React, { useState } from "react";
import {Helmet} from "react-helmet";
// Import Style
import style from "../styles/News";

// Import other Pages
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

// Import Icon
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

// Import Image
// import product from "../image/Product/Sample.jpg";
// import jomar from "../image/jomar.jpg";

import { useSelector } from "react-redux";
import usePagination from "../components/Pagination";
import { useHistory } from "react-router-dom";
// Import Material UI
import {
  Box,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Stack,
  Pagination,
} from "@mui/material";

import { format } from 'date-fns';

function News({ isAuth }) {
    const history = useHistory();
  const user = useSelector((state) => state.user);
  //pagination
  const [page, setPage] = useState(1);
  const PER_PAGE = 5;
  const handleChangePagination = (event, newValue) => {
    setPage(newValue);
    _DATA.jump(newValue);
  };
  const _DATA = usePagination(user.newsBlogs, PER_PAGE);

  const buttonSeeMore = (id) => {
    history.push(`newspost?newspost=${id}`);
  }
  return (
  
      <Box sx={{ backgroundColor: "#f2f2f2" }}>
      <Helmet>
             <title>DC Monorack - News {"&"} Blogs </title>
            <meta name="DC Monorack Home " 
            content="Lifetime warranty, Quality Motorcycle Brackets Guaranteed And Lifetime Warranty. What are you waiting for? Avail Now!" />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

        <Navbar isAuth={isAuth} />

        {/*BreadCrumbs*/}
        <Box sx={style.breadcrumbsContainer}>
          <Typography sx={style.breadcrumbsTitle}>News {"&"} Blogs</Typography>
          <Typography sx={style.breadcrumbsSubtitle}>
            Latest News and Happenings Inside DC Monorack
          </Typography>
        </Box>

        {/* Main Container */}
        <Box sx={style.mainContainer}>
          {/* Main Grid */}
          <Grid container>
            {/* Grid ng Latest */}
            <Grid item xs={12} sm={12} md={12} lg={4} sx={style.mainLeftGrid}>
              <Box sx={style.gridLeftContainer}>
                <Box>
                  <Typography sx={style.gridLeftTitle}>
                    Latest News {"&"} Blogs
                  </Typography>
                </Box>

                {/* Per Post Card */}
                { user.newsBlogs.slice(0,4).map((news, i) => (
                <Card sx={style.cardPost}>
                  <CardMedia
                    component="img"
                    image={news.Image}
                    sx={{ width: 80, display: "flex", alignItems: "center", pointerEvents: "none" }}
                  />

                  <CardContent sx={style.cardContent}>
                    <Box>
                      <Typography sx={style.postTitle}>
                        {news.Title}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography sx={style.postContent}>
                      { news.Desc }
                      </Typography>
                    </Box>

                    <Box sx={style.postDateContainer}>
                      <CalendarMonthIcon sx={style.calendarIcon} />
                      <Typography sx={style.postDate}>{format(new Date((news.Created.seconds*1000)), 'MM/dd/yyyy')}</Typography>
                    </Box>
                  </CardContent>
                </Card>
                ))}

          
              </Box>
            </Grid>

            {/* Grid ng Main News - Container */}
            <Grid item xs={12} sm={12} md={12} lg={8} sx={style.mainRightGrid}>
              <Box>
                <Grid container>
                  {/* Card Article */}
                  {_DATA.currentData().map((news, i) => (
                    <Grid item xs={6} key={i}>
                      <Card sx={style.articleCard}>
                        <CardActionArea  onClick={(e) => buttonSeeMore(news.id)}>
                          <CardMedia
                            component="img"
                            image={news.Image}
                            height="150"
                            style={{ pointerEvents: "none"}}
                          />
                        </CardActionArea>

                        <CardContent>
                          <Typography sx={style.articleTitle}>
                            {news.Title}
                          </Typography>

                          <Typography sx={style.articleContent}>
                          { news.Desc }
                          </Typography>

                          <Box sx={style.articleControlContainer}>
                            <Box sx={style.articleDateContainer}>
                              <CalendarMonthIcon sx={style.articleIcon} />
                              <Typography sx={style.articleDate}>
                              {format(new Date((news.Created.seconds*1000)), 'MM/dd/yyyy')}
                              </Typography>
                            </Box>

                            <Box>
                              <Typography sx={style.articleFullLink} onClick={(e) => buttonSeeMore(news.id)}>
                                See More
                              </Typography>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box sx={style.paginationContainer}>
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil(user.newsBlogs.length / PER_PAGE)}
                    page={page}
                    onChange={handleChangePagination}
                    variant="outlined"
                    shape="rounded"
                    sx={style.pagination}
                  />
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Footer />
      </Box>
  
  );
}

export default News;
