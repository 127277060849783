// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
    },

    mainLoginContainer: {
        width: '90%',
        margin: 'auto',
        marginBottom: '20px',
    },

    credentialsLabel: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        marginBottom: '5px'
    },

    inputBox: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #479923',
            },

            fontFamily: 'Poppins',
            fontSize: '13px',
            borderRadius: 0,
            backgroundColor: '#fff',
            marginBottom: '10px'
        },

        width: '100%',

        '& .MuiInputBase-input': {
            color: '#000',
        },
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '50%',
            lg: '50%',
        },

        margin: '20px auto 20px auto',
        padding: '10px',
    },

    warningBoxYellow: {
        marginBottom: '10px',
        backgroundColor: '#ffe0b5',
        border: '1px solid #ff9500',
        padding: '10px',
    },

    warningBoxGreen: {
        marginBottom: '10px',
        backgroundColor: '#eeffeb',
        border: '1px solid #30db12',
        padding: '10px',
    },

    warningBoxRed: {
        marginBottom: '10px',
        backgroundColor: '#ffd1d1',
        border: '1px solid #d91c1c',
        padding: '10px',
    },

    warningText: {
        fontFamily: 'poppins',
        fontSize: '14px',
    },

    recoverButton: {
        backgroundColor: '#ad230e',
        borderRadius: 0,
        boxShadow: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',
        height: '50px',
        width: '100%',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    gridButton: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },

    gridTextbox: {
        display: 'flex',
        alignItem: 'center',
    },

    gridMain: {
        justifyContent: 'space-evenly',
    },

}
export default style;