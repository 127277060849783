import React from 'react'
import {Helmet} from "react-helmet";
// Import Styling
import style from '../styles/About';

// Import other Pages
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

// Import Image
// import about1 from '../image/about1.jpg'
// import jomar from '../image/jomar.jpg'

// Import Material UI
import {
    Box,
    Typography,
    Button,
    Divider
} from '@mui/material';

function About({ isAuth }) {
    return (
      
            <Box sx={{ backgroundColor: '#f2f2f2', }}>

                 <Helmet>
             <title>DC Monorack - About Us </title>
            <meta name="DC Monorack About Us" 
            content="DC Monorack provides a wide range of motorcycle brackets that are both durable and affordable, ensuring that our customers are satisfied with our products." />
            {/* <meta name="dcmonrack:image" content="https://designcode.io/cloud/v2/twitter.jpg" /> */}
            </Helmet>

                <Navbar isAuth={isAuth} />

                {/*BreadCrumbs*/}
                <Box sx={style.breadcrumbsContainer}>
                    <Typography sx={style.breadcrumbsTitle}>About Us</Typography>
                    <Typography sx={style.breadcrumbsSubtitle}>Everything you need to know about DC Monorack</Typography>
                </Box>

                {/*Company Story*/}
                <Box sx={style.mainContainer}>
                    <Box sx={style.titleContainer}>
                        <Typography sx={style.titleLabel}>Who We Are</Typography>
                    </Box>

                    <Box sx={style.subTitleContainer}>
                        <Typography sx={style.subTitleText}>DC Monorack provides a wide range of motorcycle brackets that are both durable and affordable, ensuring that our customers are satisfied with our products.</Typography>
                    </Box>

                    <Divider sx={{ backgroundColor: '#e6e6e6', width: '60%', margin: 'auto', }} />

                    <Box sx={style.mainTextContainer}>
                        <Typography sx={style.mainText1}>
                            Q.K.D.C Steel Fabrication is a company that manufactures high-quality motorcycle brackets for various motorcycle brands and models. The company also supplies motorcycle brackets in some motorcycle shops around Luzon. The company was able to amass a large number of customers and clients over the years, not only here in Bulacan but throughout the Philippines, including Cavite, Manila, the Southern part of Luzon, Cebu City, and Davao City, allowing them to stand out from their competitors.
                        </Typography>

                        <Typography sx={style.mainText2}>
                            Q.K.D.C Steel Fabrication was founded in 2017 by Mr. Jomar Dela Cruz located in Pulilan, Bulacan. The company began as a small-scale operation. Mr. Dela Cruz began by producing a few monoracks and marketing them online. Currently, the company is based in Bagong Silang, Plaridel, Bulacan, and has grown to become well-known among motorcycle riders throughout the country. This is because the company ensures that its products are of high quality and that its customers are satisfied.
                        </Typography>
                    </Box>

                    <Divider sx={{ backgroundColor: '#e6e6e6', width: '50%', margin: 'auto', }} />

                </Box>

                {/*BreadCrumbs Deals*/}
                <Box sx={style.dealsBreadcrumbs}>

                    <Box>
                        <Typography sx={style.dealBigText}>Check Our Products</Typography>
                    </Box>

                    <Box sx={style.dealSubText1Container}>
                        <Typography sx={style.dealSubText1}>DC Monorack</Typography>
                    </Box>

                    <Box sx={style.dealSubText2Container}>
                        <Typography sx={style.dealSubText2}>Providing high-quality motorcycle brackets since 2017. Hurry! Avail our products now!</Typography>
                    </Box>

                    <Box>
                        <Button variant="contained" sx={style.goToShopButton}>
                            Go To Shop
                        </Button>
                    </Box>

                </Box>

                <Footer />
            </Box>
       
    )
}

export default About