import deals from '../image/deals.jpg';

const style = {
    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '80%',
            lg: '70%',
        },
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
    },

    banner: {
        width: '100%',
    },

    slideContent: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: {
            xs: '95%',
            sm: '90%',
            md: '60%',
            lg: '60%',
        },
        height: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    sliderSmall: {
        fontFamily: 'poppins',
        color: '#fff',
    },

    sliderBig: {
        fontFamily: 'bold',
        fontSize: {
            xs: '25px',
            sm: '40px',
            md: '45px',
            lg: '55px',
        },
        lineHeight: {
            xs: '42px',
            sm: '45px',
            md: '48px',
            lg: '50px',
        },
        marginBottom: {
            md: '15px',
            lg: '15px',
        },
        color: '#fff',
        textTransform: 'uppercase',
        textAlign: 'center',
    },

    sliderMedium: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
        fontSize: '14px',
    },

    sliderButtonContainer: {
        width: '100%',
        marginTop: '15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    sliderBuyButton: {
        borderRadius: 0,
        boxShadow: 0,
        fontFamily: 'poppins',
        backgroundColor: '#ad230e',
        margin: '2px',
        textTransform: 'capitalize',
        paddingRight: '30px',
        paddingLeft: '30px',

        '&:hover': {
            borderRadius: 0,
            boxShadow: 0,
            backgroundColor: '#7d1a0b',
        },
    },

    sliderContactButton: {
        borderRadius: 0,
        boxShadow: 0,
        margin: '2px',
        fontFamily: 'poppins',
        color: '#fff',
        textTransform: 'capitalize',
        borderColor: '#fff',

        '&:hover': {
            borderRadius: 0,
            boxShadow: 0,
            borderColor: '#fff',
        },
    },

    mainProductBox: {
        marginTop: '35px',
    },

    featuredBrandBox: {
        marginTop: '25px',
    },

    titleContainer: {
        width: '100%',
        textAlign: 'center',
        position: 'relative',
        marginTop: '50px',
    },

    homeTitle: {
        fontFamily: 'semibold',
        fontSize: '15px',
        textTransform: 'uppercase',
        letterSpacing: '3px',
        position: 'relative',
        marginRight: 'auto',
        marginBottom: '4rem',

        '&:after': {
            content: '""',
            width: '150px',
            height: '5px',
            backgroundColor: '#ad230e',
            position: 'absolute',
            bottom: '-1.5rem',
            left: '50%',
            transform: 'translateX(-50%)',
            borderRadius: '2px',
            marginBottom: '5px',
        },
    },

    productMainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: {
            xs: 'space-evenly',
            sm: 'space-evenly',
            md: 'center',
            lg: 'center',
        },
    },

    perItem: {
        margin: {
            xs: '0px 0px 5px 0px',
            sm: '0px 0px 5px 0px',
            md: '0px 8px 8px 5px',
            lg: '0px 8px 8px 5px',
        },
    },

    cardProduct: {
        maxWidth: {
            xs: 156,
            lg: 180,
        },
        borderRadius: 0,
        backgroundColor: '#f2f2f2',
        padding: '3px',
        boxShadow: 0,
        border: '1px solid #e6e6e6',
    },

    productTitle: {
        fontFamily: 'semibold',
        fontSize: '14px',
        marginBottom: '5px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
        width: '170px',
    },

    productDescription: {
        fontFamily: 'poppins',
        fontSize: '12px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        width: '170px',
    },

    boxDescriptionContainer: {
        height: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '2px',
    },

    productPrice: {
        fontFamily: 'poppins',
        color: '#cf2d00',
        fontSize: '13px',
    },

    brandGrid: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    brandImageContainer: {
        margin: '5px',
    },

    dealsBreadcrumbs: {
        height: '30vh',
        backgroundImage: `url(${deals})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        marginTop: '20px',
    },

    dealBigText: {
        fontFamily: 'bold',
        color: '#fff',
        fontSize: '30px',
        textTransform: 'uppercase',
    },

    dealSubText1Container: {
        marginTop: '10px'
    },

    dealSubText2Container: {
        marginBottom: '20px',
    },

    dealSubText1: {
        fontFamily: 'semibold',
        color: '#fff',
        textTransform: 'uppercase',
    },

    dealSubText2: {
        fontFamily: 'poppins',
        color: '#fff',
        textAlign: 'center',
    },

    goToShopButton: {
        backgroundColor: '#ad230e',
        boxShadow: 0,
        borderRadius: 0,
        fontFamily: 'poppins',
        textTransform: 'capitalize',

        '&:hover': {
            backgroundColor: '#7d1a0b',
            boxShadow: 0,
        },
    },

    imageGridGallery: {
        justifyContent: 'center',
        alignItems: 'center',
    },

    imageContainer: {
        cursor: 'pointer',
        margin: '2px',
        transition: 'all ease .5s',

        '&:hover': {
            filter: 'brightness(60%)',
        },
    },
}
export default style;