// Importing Picture
import breadcrumbs from '../image/breadcrumbs.jpg';

const style = {
    breadcrumbsContainer: {
        height: '25vh',
        backgroundImage: `url(${breadcrumbs})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
    },

    breadcrumbsTitle: {
        fontFamily: 'semibold',
        fontSize: '35px',
        color: '#fff',
    },

    breadcrumbsSubtitle: {
        fontFamily: 'poppins',
        color: '#fff',
    },

    mainContainer: {
        width: {
            xs: '90%',
            sm: '90%',
            md: '90%',
            lg: '70%',
        },
        margin: 'auto',
        marginTop: '20px',
    },

    mainPaperContainer: {
        backgroundColor: '#fff',
    },

    productImageGrid: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px'
    },

    selectedImageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },

    selectedImage: {
        width: '100%',
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '420px',
        border: '1px solid #e6e6e6',
    },

    thumbnailContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5px',
    },

    imageThumbContainer: {
        display: 'flex',
        objectFit: 'contain',
    },

    basketImageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #e6e6e6',
        width: '100%',
    },

    productDetail: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
    },

    productTitleContainer: {
        marginTop: '15px',
    },

    prodTitle: {
        fontFamily: 'Semibold',
        fontSize: '25px',
    },

    productDescriptionContainer: {
        marginTop: '20px',
    },

    prodDescription: {
        fontFamily: 'poppins',
        fontSize: '15px',
        color: '#212121',
        lineHeight: '25px',
    },

    prodDescContainer: {
        display: 'flex',
        width: {
            xs: '80%',
            sm: '70%',
            md: '60%',
            lg: '50%',
        },
        justifyContent: 'space-between',
        alignItem: 'center',
        marginTop: '25px'
    },

    prodDescLabel: {
        fontFamily: 'semibold',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
    },

    priceText: {
        fontFamily: 'Poppins',
    },

    stockText1: {
        fontFamily: 'Poppins',
        backgroundColor: '#479923',
        width: '80px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: '5px',
        fontSize: '14px',
    },

    stockText2: {
        fontFamily: 'Poppins',
        backgroundColor: '#ad230e',
        width: '120px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: '5px',
        fontSize: '14px',
        // display: 'none',
    },

    productItemCountDrawerContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    addlessButton: {
        borderRadius: 0,
        fontFamily: 'semibold',
        color: '#ad230e',
        borderColor: '#e6e6e6',
        padding: 0,
        minWidth: '30px',

        '&:hover': {
            transition: 'all ease .4s',
            color: '#000',
            border: '1px solid #ad230e',
            backgroundColor: 'transparent',
        },
    },

    productQtyDrawerInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#e6e6e6',
            },
            '&:hover fieldset': {
                borderColor: '#e6e6e6',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid #e6e6e6',
            },

            fontFamily: 'Poppins',
            fontSize: '12px',
            padding: 0,
            width: '40px',
            height: '26.5px',
            borderRadius: 0,
        },

        '& .MuiInputBase-input': {
            color: '#000',
            padding: 0,
            textAlign: 'center',
        },

    },

    prodButtonContainer: {
        display: 'flex',
        alignItem: 'center',
        marginTop: '25px'
    },

    addToCartButton: {
        fontFamily: 'semibold',
        borderRadius: 0,
        boxShadow: 0,
        backgroundColor: '#fff',
        color: '#ad230e',
        border: '2px solid #ad230e',
        marginRight: '10px',

        '&:hover': {
            boxShadow: 0,
            backgroundColor: '#f2e8e6',
        },
    },

    buyNowButton: {
        fontFamily: 'semibold',
        borderRadius: 0,
        boxShadow: 0,
        backgroundColor: '#ad230e',
        color: '#fff',
        height: '40px',

        '&:hover': {
            boxShadow: 0,
            backgroundColor: '#7d1a0b',
        },
    },

    alertContainer: {
        marginTop: '20px',
        backgroundColor: '#ffe0b5',
        border: '1px solid #ff9500',
        padding: '10px',
    },

    alertText: {
        fontFamily: 'Poppins',
        fontSize: '14px',
    },

    suggestedContainer: {
        backgroundColor: '#fff',
        marginTop: '20px',
        padding: '10px',
        maxWidth: '100%',
    },

    suggestedOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px',
    },

    titleText: {
        fontFamily: 'semibold',
    },
    
    textSeeAll: {
        color: '#ad230e',
        fontFamily: 'poppins',

        '&:hover': {
            color: '#D47033',
        },
    },

    mainProductContainer: {
        marginTop: '10px',
        justifyContent: {
            xs: 'center',
            sm: 'center',
            md: 'center',
            lg: 'space-evenly',
            xl: 'center',
        },
    },

    perItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '5px',
        margin: {
            lg: '0px 5px 0px 5px',
        },
    },

    cardProduct: {
        maxWidth: {
            xs: 140,
            lg: 190,
        },
        borderRadius: 0,
        backgroundColor: '#f2f2f2',
        padding: '3px',
        marginBottom: '5px',
    },

    boxTitleContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    productTitle: {
        fontFamily: 'semibold',
        fontSize: '14px',
        marginBottom: '5px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '1',
        WebkitBoxOrient: 'vertical',
    },

    productDescription: {
        fontFamily: 'poppins',
        fontSize: '12px',
        color: '#000',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },

    boxDescriptionContainer: {
        height: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '2px',
    },

    productPrice: {
        fontFamily: 'poppins',
        color: '#cf2d00',
        fontSize: '13px',
    },

}
export default style;